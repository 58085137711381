@import "src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"


.wrapper
  overflow: hidden
  
.innerWrapper
  width: 1440px

  padding: 25px 80px
  margin: 0 auto

.textWrapper
  display: flex
  flex-direction: column
  align-items: center

  width: 500px
  margin: 0 auto
  margin-top: 100px

.title
  @include header(35px)
  text-align: center

  margin-bottom: 24px

.error
  @include header(120px)

  display: flex
  column-gap: 128px

  margin-bottom: 24px

  background-image: url("/assets/images/сircle.svg")
  background-repeat: no-repeat
  background-position: 52% 34%
  background-size: 218px 218px

.button
  position: relative
  z-index: 1

  &::before
    @include line
    top: 62%
    left: -100vw
    right: 0
    z-index: -1

  &::after
    @include line
    top: 50%
    left: -15%
    right: -100vw
    z-index: -1