@import '../../../assets/styles/global.sass'

.buttonIcon
  display: flex
  width: fit-content
  border-radius: 50%
  color: $c-additional
  transition: all .1s

  &:disabled
    cursor: default
    color: $c-disabled

  &:not(:disabled)
    &:hover
      color: white

    &:active
      color: $c-additional


.error
  color: $c-error

  &:hover
    color: red

  &:active
    color: $c-error