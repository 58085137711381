.keyBenefis__wrapper
  padding-top: 100px

  @media (min-width: 1730px)
    padding-top: 110px

  @media (max-width: 900px)
    padding-top: 56px

  @media (max-width: 500px)
    padding-top: 54px

.keyBenefis__header
  color:  #FFF
  text-align: center
  font-family: Roboto
  font-size: 38px
  font-style: normal
  font-weight: 700
  line-height: normal

  @media (max-width: 900px)
    font-size: 28px
    font-weight: 500

  @media (max-width: 500px)
    font-size: 24px
    font-weight: 500

.keybenefis__graph
  padding-top: 50px
  display: flex
  justify-content: center
  // width: 100%

  @media (max-width: 1730px)
    padding-top: 70px

.keyBenefis__info
  width: 400%
  padding-top: 50px
  display: flex
  gap: 20px
  user-select: none

  @media (min-width: 1730px)
    padding-top: 70px

  @media (max-width: 900px)
    padding-top: 24px

  @media (max-width: 500px)
    padding-top: 12px

.keyBenefis__info_block
  width: 325px
  display: flex
  flex-shrink: 0
  align-items: center
  height: 169px
  padding: 50px 20px
  border-radius: 30px
  background: #384053
  position: relative
  overflow: hidden
  animation: slidein 40s linear infinite
  cursor: pointer
  font-family: Roboto
  font-size: 20px
  font-style: normal
  font-weight: 400
  line-height: normal
  
  @media (min-width: 1730px)
    width: 345px
    padding: 50px 30px

  @media (max-width: 900px)
    width: 257px
    height: 124px
    padding: 30px 30px
    font-size: 18px

  @media (max-width: 500px)
    width: 198px
    height: 116px
    padding: 20px 12px
    font-size: 16px

  @keyframes slidein
    0%, 100%
      transform: translateX(25%)
    50%
      transform: translateX(-100%)
