.advertising__wrapper
  position: absolute
  width: 412px
  height: 152px
  background: #0261B1
  border-radius: 30px
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
  top: 85px
  right: 40px
  z-index: 9999
  padding: 20px 40px

  @media (max-width: 900px)
    display: none

.advertising__text
  font-size: 20px
  font-style: normal
  font-weight: 400
  line-height: 140%

.infoClose
  position: absolute
  top: 15px
  right: 20px