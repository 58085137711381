@import "/src/assets/styles/mixins.sass"
@import "/src/assets/styles/global.sass"

.profileWrapper
  display: flex
  flex-direction: column

.profileWrapper .modal-window_closeButton
  &::before
    height: 11px

  &::after
    height: 11px

.header
  @include header(20px)
  display: flex
  align-items: center
  margin-bottom: 40px

.wrapper
  display: flex
  justify-content: center
  gap: 32px
  margin-bottom: 32px
  padding-left: 250px

.columnWrapper
  max-width: 310px
  width: 100%

.pricing
  margin-bottom: 30px

.back
  position: relative
  display: flex
  align-items: center
  width: 100%
  text-decoration: none

.backTitle
  display: flex
  color: #FFFFFF
  text-decoration: none
  background-image: linear-gradient(#B63940, #B63940)
  background-position: 0% 100%
  background-repeat: no-repeat
  background-size: 0% 2px
  transition: background-size .3s
  &:hover
    background-size: 100% 2px
    color: #d9d9d9
  &:active
    color: #d9d9d9

.passWrapper
  display: flex
  justify-content: center
  padding-left: 240px
  padding-bottom: 80px

.footer
  left: 195px
  bottom: 0
  width: 100%

.footerHr
  background-color: rgba(133, 134, 165, 0.60)
  border: none
  height: 1px

@media screen and (max-width: 1348px)
  .profileWrapper
    padding: 20px 16px 50px 0

@media screen and (max-width: 1100px)
  .wrapper
    gap: 30px

  .columnWrapper
    width: 100%

