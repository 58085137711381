@import "src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.header
  display: flex
  justify-content: space-between
  height: 80px
  width: calc(100% - 12rem)
  background-color: #1A2235
  position: fixed
  z-index: 2

  @media (max-width: 500px)
    height: 136px
    width: 100%
    display: block

.header__blocks
  display: flex
  align-items: center
  padding: 0 16px

  @media (max-width: 500px)
    display: block
    padding: 0

.header__links
  display: flex
  align-items: center

  @media (max-width: 500px)
    flex-direction: column
    gap: 8px

.block__left
  display: flex
  align-items: center
  padding: 0 16px
  gap: 36px

  @media (max-width: 500px)
    padding: 0 20px
    width: 100%
    justify-content: flex-end
    height: 72px
    background: #242C3F
    
.block__right
  display: flex
  align-items: center
  padding: 0 16px
  gap: 36px

  @media (max-width: 500px)
    padding: 0 20px
    width: 100%
    justify-content: space-between

.notification__icon
  display: flex
  align-items: center
  padding: 0 16px
  gap: 36px

  @media (max-width: 500px)
    gap: 16px

.header__wallet
  display: flex
  gap: 14px
  align-items: center

.balance__value
  font-weight: 500
  font-size: 18px

.header__wallet_btn
  border-radius: 16px
  padding: 8px 24px
  width: 145px
  height: 38px
  background: #8586A5

  @media (max-width: 500px)
    display: none

.headerLocation
  display: flex
  align-items: center
  padding: 0 20px

  @media (max-width: 500px)
    display: none 

.adminToggle
  @media (max-width: 500px)
    padding-left: 20px

.headerLocationText
  font-size: 20px

.profile
  display: flex

.userAvatar
  padding: 0 12px

.header__logo
  display: none

  @media (max-width: 500px)
    display: block
    position: relative
    margin-right: auto
    margin-left: auto

.avatarImg
  width: 50px
  height: 50px
  border-radius: 50%
  border: 2px solid var(--Red, $c-additional)
  background: lightgray
  background-size: 50px 50px

.userData
  display: flex
  flex-direction: column
  justify-content: center
  gap: 4px
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: normal

.email
  color: var(--Gray, #8586A5)

.header__icon
  display: flex
  justify-content: center
  align-items: center

  > svg
      width: 24px
      height: 24px

.balanceWrapper
  display: flex
  align-items: center
  font-size: 16px
  font-style: normal
  font-weight: 500
  line-height: normal
  cursor: pointer

.wallet
  padding: 0 12px

.discount
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background: linear-gradient(45deg, #355C7D, #6C5B7B, #C06C84)
  background-size: 400% 400%
  animation: gradient 10s ease infinite
  padding: 4px 16px
  border-radius: 8px

  @media (max-width: 500px)
    display: none

@keyframes gradient
  0%
    background-position: 0 50%
  50%
    background-position: 100% 50%
  100%
    background-position: 0 50%

.documentsLink 
  &:hover
    color: $c-additional
    cursor: pointer
  &:active
    color: $c-additional-active




// .header__icon_logout
//   transition: all 0.15s ease-in

//   >svg
//     color: $c-accent

// .header__link_logout:hover
//   opacity: 1

//   >span
//     color: $c-accent

//   .header__icon_logout
//     background-color: $c-accent

//     >svg
//       color: #ffffff

// @media screen and (max-width: 1048px)
//   .header__icon
//     width: 30px
//     height: 30px
//     border-radius: 30%

// @media screen and (max-width: 1197px)
//   .header__links
//     gap: 8px

//   .adminToggle
//     margin-right: 0

//   .header__logoWrapper
//     margin-right: 16px

// @media screen and (max-width: 1268px)
//   .header__currentPage
//     display: none

// .header__logoWrapper
//   margin-right: 2rem

// .header__currentPage
//   margin-right: auto
//   font-family: 'Roboto', sans-serif
//   font-size: 14px
//   font-weight: 700
//   color: #ffffff