.text
  font-size: 14px
  text-align: justify
  min-width: 403px

  @media (max-width: 500px)
    text-align: left
    margin-top: -14px

.link
  text-decoration: underline

.link:hover
  opacity: 0.8

@media screen and (max-width: 450px)
  .text
    min-width: auto