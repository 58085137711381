@import "/src/assets/styles/mixins.sass"

.wrapper
  display: flex
  justify-content: center
  gap: 68px

.adminColumn
  max-width: 310px

.avatarWrapper
  display: flex
  flex-direction: column
  align-items: center
  gap: 8px

.avatar
  position: relative

.avatarImg
  width: 168px
  height: 168px
  border-radius: 50%
  border: 3px solid $c-additional
  background: lightgray
  background-size: 168px

.avatarInput
  display: none

.avatarlabel
  position: absolute
  bottom: 0
  right: 0
  cursor: pointer

.pictureError
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 16px
  color: $c-error

.pictureInfo
  color:  #8586A5
  font-size: 12px

.wrapper_mainInfo
  display: flex
  gap: 40px
  padding-top: 20px

.name
  width: 310px
  display: flex
  flex-direction: column
  gap: 40px

.firstName
  height: 50px
  width: 310px

.lastName
  height: 50px
  width: 310px

.emailTel
  width: 310px
  display: flex
  flex-direction: column
  gap: 40px

.email
  height: 50px
  width: 310px

.telephone
  height: 50px
  width: 310px

.headerPass
  padding-top: 40px

.pass
  display: flex
  gap: 40px
  padding-top: 20px

.currentPass
  width: 310px

.changePass
  width: 310px
  display: flex
  flex-direction: column
  gap: 40px

.newPass
  height: 50px
  width: 310px

.repeatNewPass
  height: 50px
  width: 310px

.headerAdditionally
  padding-top: 40px

.additionally
  display: flex
  gap: 40px
  padding-top: 20px

.timeZone
  width: 310px

.promocode
  width: 310px

.buttonSave
  display: flex
  justify-content: flex-end
  padding-top: 40px

.loader
  padding-left: 143px

.btn
  width: 310px
  height: 50px
  border: 1px solid #B63940
  border-radius: 8px
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal
  transition-duration: 0.5s
  &:hover
    background-color: #B63940
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px -4px 10px rgba(0, 0, 0, 0.25)
    transition-duration: 0.5s
  &:active
    background-color: #f50814
    transition-duration: 0.1s

.InputMask
  max-height: 56px
  display: flex
  position: relative
  outline: 1px solid $c-additional
  border-radius: 4px
  width: 100%
  padding: 16px
  background-color: transparent
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
  border-radius: 4px
  font-size: 16px
  line-height: 24px
  color: $c-support-text
  max-width: 413px
  &:hover
    outline-width: 2px
  &:focus
    outline-width: 2px
  &:active
    outline-width: 2px