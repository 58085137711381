@import "src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.wrapper
  display: flex
  flex-direction: column
  gap: 24px
  padding-bottom: 40px

.title
  @include header(20px)
  grid-area: title

.costWrapper
  display: flex
  align-items: center
  justify-content: space-between
  padding-bottom: 20px
  background-color: #2B3243
  border-radius: 8px
  padding: 16px
  height: 80px

.inputWrapper
  display: flex
  gap: 40px

.inputWrap
  position: relative

.errorText
  position: absolute
  color: red
  font-size: 12px
  padding-top: 4px

.costMessage
  display: flex
  align-items: center
  gap: 8px

.costImg
  display: flex
  align-items: center
  gap: 8px

.currentCost
  display: flex
  flex-direction: column
  font-size: 12px

.btnWrapper
  text-align: right

.costMailing
  max-height: 30px
  width: 100px
  display: flex
  position: relative
  outline: 1px solid $c-additional
  border-radius: 4px
  padding: 16px
  background-color: transparent
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
  border-radius: 4px
  font-size: 16px
  text-align: center
  line-height: 24px
  color: $c-support-text
  max-width: 413px
  &:hover
    outline-width: 2px
  &:focus
    outline-width: 2px
  &:active
    outline-width: 2px