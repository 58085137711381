@import "/src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.tasks
  width: 54%

.title
  margin-bottom: 16px
  font-family: 'Roboto', sans-serif
  font-size: 16px
  font-weight: 500
  line-height: 24px

.tasksWrapper
  background-color: $c-secondary
  border-radius: 8px
  padding-bottom: 24px
  padding-left: 16px
  padding-right: 16px

  ::-webkit-scrollbar
    display: block

.toolsTabs
  display: flex
  justify-content: space-between
  align-items: center

.helpingBlock
  display: flex
  gap: 11px

.refreshWrapper

.refresh
  display: flex
  gap: 8px
  align-items: center
  border: none
  font-size: 14px
  font-weight: 400
  text-decoration: none
  color: $c-wild-blue
  cursor: pointer
  user-select: none
  appearance: none
  touch-action: manipulation

  &:hover
    color: #ffffff

.refreshIcon
  cursor: pointer
  width: 14px
  height: 14px

.refreshText
  margin: 0

.search
  color: var(--Gray, #8586A5)
  background-color: $c-secondary
  border: 1px solid var(--Gray, #8586A5)
  -moz-border-radius: 4px
  -webkit-border-radius: 4px
  padding: 4px
  font-size: 12px
  font-weight: lighter
  background-image: url('../../assets/images/icons/search.svg')
  background-size: 16px 16px
  background-repeat: no-repeat
  background-position: 4px
  outline: none
  padding-left: 24px

.button
  @include tool-button

.toolsItems
  width: 100%
  height: 100%
  max-height: 500px
  overflow: hidden scroll
  // overflow-y: auto
  padding-top: 8px
  scrollbar-width: thin

.toolsList
  position: relative
  padding-right: 8px

.tasksNoData
  align-self: center
  margin: 25vh auto
  color: $c-wild-blue
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal
  text-align: center

  @media screen and (min-width: 1900px)
    font-size: 20px
