.wrapper
  width: 870px
  display: flex
  flex-direction: column
  gap: 24px

  @media (min-width: 1730px)
    width: 944px

.nameTask__wrapper
  width: 100%
  padding: 16px 16px 24px 16px
  background: #242C3F
  display: flex
  flex-direction: column
  gap: 8px
  border-radius: 16px

.greenBorder
  border: 1px solid #399830

.header
  color: #8586A5
  font-size: 18px
  font-weight: 400
  line-height: normal

.input
  width: 100%
  border-bottom: 1px solid #8586A5
  background: none
  height: 40px
  padding: 8px 0px

.text
  color: #8586A5
  font-size: 14px
  font-weight: 400
  line-height: normal

.group__wrapper
  width: 100%
  padding: 16px 16px 24px 16px
  background: #242C3F
  display: flex
  flex-direction: column
  gap: 24px
  border-radius: 16px

.group__qty
  display: flex
  flex-direction: column
  gap: 8px

.group__count
  display: flex
  gap: 16px
  color: #8586A5
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: normal

.count_sign
  width: 24px
  height: 24px
  padding: 10px
  border-radius: 5px
  background: #565E71
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  user-select: none

.group
  display: flex
  flex-direction: column
  gap: 16px

.input__group
  width: 100%
  background: none
  padding: 16px 20px
  border-radius: 8px
  border: 1px solid #384053
  color: #8586A5

.contacts__wrapper
  width: 100%
  padding: 16px 16px 24px 16px
  border-radius: 16px
  background: #242C3F
  display: flex
  flex-direction: column
  gap: 8px

.btn__group
  display: flex
  color: #8586A5
  text-align: center
  font-family: Inter
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: normal

  @media (min-width: 1730px)
    font-size: 18px

.btn__left
  padding: 12px 20px
  border-radius: 8px 0px 0px 8px
  border: 1px solid #8586A5
  background: none

  &:hover
    border: 1px solid white

  &:active
    border: 1px solid #399830

.btn__center
  padding: 12px 20px
  border: 1px solid #8586A5
  background: none

  &:hover
    border: 1px solid white

  &:active
    border: 1px solid #399830

.btn__right
  padding: 12px 20px
  border-radius: 0px 8px 8px 0px
  border: 1px solid #8586A5
  background: none

  &:hover
    border: 1px solid white

  &:active
    border: 1px solid #399830

.parserTask
  position: absolute
  margin-top: 60px
  background: #1A2235
  width: 566px
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25)

.parsing__list
  display: flex
  flex-direction: column
  align-items: flex-start
  cursor: pointer

.parsing__item
  width: 100%
  padding: 8px 12px
  border-top: 1px solid #8586A5
  display: flex
  justify-content: flex-start
  color: #8586A5
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: 24px

  &:hover
    background: #8586A5
    color: #FFF

.handleContact
  width: 100%
  height: 106px
  padding: 12px 16px
  border-radius: 16px
  background: none
  border: 1px solid #8586A5

.contact__bottomText
  font-family: Inter
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: normal
  color: #8586A5
  width: 100%
  border-bottom: 1px solid #399830

.date
  display: flex
  flex-direction: column
  gap: 8px

.date__wrapper
  width: 337px
  padding: 16px 16px 24px 16px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  background: #242C3F
  border-radius: 8px

.date__header_block
  height: 48px
  display: flex
  justify-content: center
  align-items: center

.btn__calculation
  display: flex
  width: 368px
  height: 57px
  padding: 16px
  justify-content: center
  align-items: center
  border-radius: 8px
  border: 1px solid #399830
  background: #242C3F

  &:hover
    border: 1px solid #399830
    background: #1A2235

  &:active
    background: #399830

.calculated__wrapper
  display: flex
  padding: 8px 20px 20px 20px
  flex-direction: column
  align-items: flex-start
  gap: 16px
  border-radius: 8px
  border: 2px solid #384053

.errors
  color: red

