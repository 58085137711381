@import "src/assets/styles/mixins.sass"
@import "src/assets/styles/variables.sass"

::-webkit-calendar-picker-indicator
  filter: invert(1)

.mailing
  display: flex
  flex-direction: column
  gap: 40px

.mailingForm
  margin-left: 16px
  padding-bottom: 32px

  display: flex
  flex-direction: column
  width: 100%

.info
  display: flex
  gap: 96px
  min-width: 716px
  width: calc(54% + 96px)

  @media screen and (min-width: 1900px)
    width: calc(88% + 96px)

.stepsControls
  margin-top: 30px

  display: flex

.button
  @include submit-button

  &:focus-visible
    @include element-focus

.backButton
  background-color: transparent

  font-size: 12px

  &:disabled
    opacity: 0.4
    transform: none

.radioLabel
  font-weight: 500
  font-size: 16px
  margin-bottom: 24px
  display: flex

  &::after
    padding-left: 8px
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M9.91083 8.34656C9.89815 8.36768 9.88144 8.3861 9.86165 8.40075C9.84185 8.41541 9.81936 8.42603 9.79546 8.43199C9.77157 8.43795 9.74673 8.43915 9.72237 8.43551C9.69801 8.43187 9.6746 8.42347 9.65349 8.41078L6.18755 6.33094V10.125C6.18755 10.1747 6.1678 10.2224 6.13263 10.2576C6.09747 10.2927 6.04978 10.3125 6.00005 10.3125C5.95032 10.3125 5.90263 10.2927 5.86747 10.2576C5.8323 10.2224 5.81255 10.1747 5.81255 10.125V6.33094L2.34661 8.41078C2.3255 8.42346 2.30209 8.43186 2.27773 8.4355C2.25337 8.43913 2.22854 8.43793 2.20464 8.43197C2.15638 8.41993 2.11488 8.3892 2.08927 8.34656C2.06366 8.30392 2.05604 8.25285 2.06808 8.20459C2.08012 8.15633 2.11085 8.11483 2.15349 8.08922L5.63536 6L2.15349 3.91078C2.13237 3.8981 2.11396 3.88139 2.09931 3.86159C2.08466 3.84179 2.07404 3.81931 2.06808 3.79541C2.05604 3.74715 2.06366 3.69608 2.08927 3.65344C2.10195 3.63232 2.11867 3.61391 2.13846 3.59926C2.15826 3.58461 2.18074 3.57399 2.20464 3.56803C2.2529 3.55599 2.30397 3.56361 2.34661 3.58922L5.81255 5.66906V1.875C5.81255 1.82527 5.8323 1.77758 5.86747 1.74242C5.90263 1.70725 5.95032 1.6875 6.00005 1.6875C6.04978 1.6875 6.09747 1.70725 6.13263 1.74242C6.1678 1.77758 6.18755 1.82527 6.18755 1.875V5.66906L9.65349 3.58922C9.6746 3.57654 9.69801 3.56814 9.72237 3.5645C9.74673 3.56087 9.77156 3.56207 9.79546 3.56803C9.81936 3.57399 9.84184 3.58461 9.86164 3.59926C9.88144 3.61391 9.89815 3.63232 9.91083 3.65344C9.92351 3.67455 9.93191 3.69796 9.93554 3.72232C9.93918 3.74668 9.93798 3.77151 9.93202 3.79541C9.92606 3.81931 9.91544 3.84179 9.90079 3.86159C9.88614 3.88139 9.86773 3.8981 9.84661 3.91078L6.36474 6L9.84661 8.08922C9.86773 8.1019 9.88615 8.11861 9.9008 8.1384C9.91546 8.1582 9.92608 8.18069 9.93204 8.20459C9.938 8.22848 9.9392 8.25332 9.93556 8.27768C9.93192 8.30204 9.92352 8.32545 9.91083 8.34656Z" fill="%23B63940"/></svg>')

  @media screen and (min-width: 1900px)
    font-size: 18px

.wrapperMailingRadio
  display: flex
  flex-direction: column
  align-items: baseline
  width: 100%
  position: relative

.wrapperInsctructions
  position: relative
  display: flex
  justify-content: center
  flex-direction: column
  gap: 10px
  width: 100%

.wrapperInsctructionsTitle
  font-size: 16px
  font-weight: 500
  line-height: normal

.wrapperInsctructionsLink
  font-size: 16px
  font-weight: 400
  line-height: 1.5
  text-decoration-line: underline
  color: $c-additional
  cursor: pointer

.formRadioGroup
  display: flex
  min-width: 310px
  width: 27%
  gap: 10px

  @media screen and (min-width: 1900px)
    width: 40%

.formRadioGroupBtn
  display: flex
  gap: 10px
  margin-bottom: 4px
  width: 100%

.formRadioGroupBtn input[type=radio]
  display: none

.formRadioGroupBtn label
  display: flex
  align-items: center
  cursor: pointer
  color: white
  padding: 11px
  line-height: 26px
  border: 1px solid $c-additional
  border-radius: 4px
  user-select: none

  &:hover
    border: 1px solid white

.formRadioGroupBtn input[type=radio]:checked + label
  background: transparent
  border: 1px solid $c-validation-green
  box-shadow: 0px 0px 20px 0px rgba(133, 134, 165, .1)
  color: white
  width: 100%
  position: relative

  &::before
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M12.6364 6.81818L7.95775 11.7682C7.78029 11.9561 7.49247 11.9561 7.31495 11.7682L5.36364 9.70371M17 9C17 13.4183 13.4183 17 9 17C8.50327 17 8.01716 16.9548 7.54545 16.8681C4.08468 16.2324 2.77771 14.417 2.09091 13.3636C1.72457 12.802 1 11.5455 1 9C1 8.37462 1.07177 7.76589 1.20751 7.18182C1.49983 5.92401 2.09091 3.90909 4.63636 2.09091C4.97821 1.84673 6.45455 1 9 1C13.4183 1 17 4.58172 17 9Z" stroke="%23399830" stroke-linecap="round"/></svg>')
    position: absolute
    top: 50%
    right: 16px
    transform: translateY(-40%)

.formRadioGroupBtn label:hover
  color: $c-additional

.formGroup
  display: flex
  flex-direction: column
  width: 27%
  min-width: 310px

  @media screen and (min-width: 1900px)
    width: 40%

.subText
  font-size: 12px
  color: $c-light-grey

.label
  font-weight: 700
  margin-bottom: 20px

.labelImage
  font-weight: 700

  @media screen and (min-width: 1900px)
    font-size: 18px

.inputImage
  position: absolute
  z-index: -1
  opacity: 0

.image
  display: block
  margin-top: 24px
  width: 60px
  height: 60px
  object-fit: contain

.imageButton
  position: relative
  text-align: center
  cursor: pointer
  color: $c-support-text
  padding: 8px 15px
  line-height: 34px
  border: 1px solid $c-additional
  border-radius: 4px
  user-select: none
  width: 100%

  &:hover
    outline: 1px solid $c-additional

  &::after
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28" fill="none"><path d="M25 13.1888L13.6533 24.7819C12.2633 26.2021 10.378 27 8.41216 27C6.44633 27 4.56102 26.2021 3.17097 24.7819C1.78092 23.3616 1 21.4354 1 19.4269C1 17.4183 1.78092 15.4921 3.17097 14.0718L14.5176 2.47875C15.4443 1.53192 16.7012 1 18.0117 1C19.3223 1 20.5792 1.53192 21.5059 2.47875C22.4326 3.42557 22.9532 4.70975 22.9532 6.04876C22.9532 7.38778 22.4326 8.67195 21.5059 9.61878L10.1469 21.2119C9.68353 21.6853 9.05509 21.9512 8.39981 21.9512C7.74454 21.9512 7.1161 21.6853 6.65275 21.2119C6.1894 20.7384 5.92909 20.0964 5.92909 19.4269C5.92909 18.7573 6.1894 18.1153 6.65275 17.6418L17.1351 6.94442" stroke="%238586A5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    position: absolute
    right: 3%
    top: 20%

.wrapperMailingName
  display: flex
  align-items: baseline
  margin-bottom: 24px

.stepsInstruction
  display: flex
  width: 385px
  margin-top: 35px
  color: $c-additional

.stepsInstructionImage
  display: flex
  width: 385px
  margin-top: 35px
  margin-left: 120px
  color: $c-additional

.stepNumber
  align-items: center
  width: 20px
  height: 20px
  margin-bottom: 16px
  justify-content: center
  display: flex
  padding: 1rem
  border-radius: 50%
  border: 1px solid $c-accent

.mailingChannelWrapper
  padding-left: 47px
  display: flex
  flex-wrap: wrap

.fieldValidationBorder
  padding-bottom: 40px
  border-left: 1px dashed $c-wild-blue
  position: relative

  &::before
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%238586A5" fill="%231A2235"/></svg>')
    position: absolute
    top: 0
    left: 0
    transform: translate(-53%, -20%)

  &:first-of-type
    padding-top: 0

  &:last-of-type
    margin: 0 0 40px
    padding-bottom: 0

    &::after
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%238586A5" fill="%231A2235"/></svg>')
      position: absolute
      bottom: 0
      left: 0
      transform: translate(-53%, 50%)

  &.success
    border-left: 1px dashed $c-validation-green

    &::before
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%23399830" fill="%23399830"/></svg>')

  &.errors
    border-left: 1px dashed $c-accent

    &::before
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%23B63940" fill="%23B63940"/></svg>')

  &.lastNoBorder
    border-left: none

.mailingChannelWrapperStep
  display: flex
  flex-direction: column
  position: relative
  width: 100%

.mailingSaleWA
  color: $c-additional
  font-size: 14px

.mailingImageMT
  margin-top: 8px

.imageWrapper
  padding: 12px 0 0 12px

.dateInput
  background-color: #1A2235
  padding: 16px
  margin-top: 20px
  border: 1px solid #8586A5
  border-radius: 4px
  position: relative

  &::-webkit-calendar-picker-indicator
    cursor: pointer
    background: transparent
    bottom: 0
    color: transparent
    cursor: pointer
    height: auto
    left: 0
    position: absolute
    right: 0
    top: 0
    width: auto

.mailingResultsWrapper
  display: flex
  gap: 22px
  width: 500px
  margin-top: 20px

.mailingResultsElement
  width: 150px
  padding: 12px
  background-color: #2B3243
  border-radius: 4px

.mailingResultsInfo
  font-size: 12px
  color: #8586A5
  padding-left: 8px
  width: 100%

.mailingResultsInfoValue
  font-size: 20px
  margin-top: 12px

.mailingBtnContainer
  display: flex
  width: 500px
  gap: 16px
  margin-left: 48px

.stepsInstructionDate
  display: flex
  width: 385px
  margin-top: 35px
  margin-left: 250px
  color: $c-additional

.loaderContainer
  display: flex
  justify-content: center

.excessBalanceError
  margin-left: 48px
  padding: 16px 0
  color: #B3261E
  font-size: 14px