.wrapper
  display: flex
  flex-direction: column
  gap: 24px

.text
  color: green
  font-size: 12px

.error
  color: red
  font-size: 12px

.textWrapper
  display: flex
  flex-direction: column
  gap: 12px
  color: #8586A5
  border: 1px solid #8586A5
  border-radius: 8px
  padding: 8px

.textHeader
  font-size: 18px

.textList
  display: flex
  gap: 40px
  font-size: 12px
  list-style-type: disc
  padding-left: 20px
