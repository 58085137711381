@import "src/assets/styles/variables.sass"

.infoBanner
  padding: 20px
  // width: calc(60.7vw + 312px)
  border-radius: 8px
  font-size: 14px
  position: relative

  &.ad
    background: linear-gradient(86deg, #355C7D 4.43%, #6C5B7B 55.73%, #C06C84 100%)
    background-size: 200% 200%
    animation: linearAnimation 3s ease infinite

  &.tech
    background-color: $c-secondary

  &.adStart
    background: linear-gradient(86deg, #355C7D 4.43%, #6C5B7B 55.73%, #C06C84 100%)
    background-size: 200% 200%
    animation: linearAnimation 10s ease infinite
    position: absolute
    width: 45%
    padding-right: 34px
    right: 0
    bottom: -11px
    transform: translateY(50%)

    @media screen and (min-width: 1440px)
      width: 35%

    @media screen and (min-width: 1900px)
      width: 33%

    @media screen and (max-width: 450px)
      width: 100%
      padding: 8px 16px

@keyframes linearAnimation
  0%
    background-position: 10% 0%
  50%
    background-position: 91% 100%
  100%
    background-position: 10% 0%

.infoText
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: normal
  white-space: pre-line

  &.adStart
    font-size: 12x

    @media screen and (min-width: 1440px)
      font-size: 16px

    @media screen and (min-width: 1900px)
      font-size: 1.2rem

  &.ad
    @media screen and (min-width: 1900px)
      font-size: 1.2rem

  &.tech
    color: $c-wild-blue
    position: relative

    &::before
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="25" viewBox="0 0 10 25" fill="none"><path d="M5 20C5.53043 20 6.03914 20.2107 6.41421 20.5858C6.78929 20.9609 7 21.4696 7 22C7 22.5304 6.78929 23.0391 6.41421 23.4142C6.03914 23.7893 5.53043 24 5 24C4.46957 24 3.96086 23.7893 3.58579 23.4142C3.21071 23.0391 3 22.5304 3 22C3 21.4696 3.21071 20.9609 3.58579 20.5858C3.96086 20.2107 4.46957 20 5 20ZM7 17H3V16.871C2.99982 11.6451 2.33891 6.44013 1.033 1.38L1 1.25V1H9V1.25L8.967 1.379C7.66108 6.43913 7.00017 11.6441 7 16.87V17Z" stroke="%23B63940"/></svg>')
      position: absolute
      left: -12px
      top: -6px
      transform: translateX(-50%)

.infoLink
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: 32px
  white-space: pre-line

@media screen and (min-width: 1440px)
  .infoLink
    font-size: 16px

@media screen and (min-width: 1900px)
  .infoLink
    font-size: 1.2rem

.infoClose
  position: absolute

  &.ad
    top: 8px
    right: 8px

  &.adStart
    top: 8px
    right: 8px

  &.tech
    top: 16px
    right: 16px


