@import "src/assets/styles/variables.sass"

.wrapper
  display: grid
  max-width: initial
  margin: 0 auto
  grid-template-columns: 12rem

  @media (max-width: 500px)
    grid-template-columns: none

.wrapper div:nth-child(1)
  grid-column: 1 / -1

.wrapper div:nth-child(2)
  grid-column: -1 / 2

.wrapper div:nth-child(3)
  grid-column: -1 / 2

.header
  grid-area: header
  // height: 80px

.navbar
  position: fixed
  z-index: 3
  grid-area: navbar
  background-color: $c-secondary
  display: flex
  min-height: 100vh
  padding: 0 8px
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.25), 0px 4px 10px 0px rgba(0, 0, 0, 0.25)

  @media (max-width: 500px)
    background-color: transparent
    box-shadow: none
    padding: 0

.main
  grid-area: main
  padding: 0 28px
  margin-top: 120px

  @media (max-width: 500px)
    margin-top: 150px
    padding: 0

