@import "src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.container
  padding: 24px 24px
  background-color: $c-secondary
  border-radius: 8px
  height: 100%

.innerContainer
  position: relative
  width: 100%
  // display: flex

  height: auto
  overflow-y: auto
  overflow-x: hidden

  scrollbar-width: thin

  &::-webkit-scrollbar
    display: block

  &.noScrollInnerContainer
    position: relative
    width: 100%

    height: auto
    overflow-y: auto
    overflow-x: hidden

    &::-webkit-scrollbar
      display: none

.table
  width: 100%

  border-collapse: collapse

.table__header
  position: sticky
  top: 0px
  text-align: left
  z-index: 2

.table__body
  font-size: 14px
  font-weight: lighter
  max-height: 100%
  overflow-y: auto

.table__headCell
  padding-bottom: 24px
  font-size: 16px
  font-style: normal
  font-weight: 500
  line-height: normal
  background-color: $c-secondary

.table__cell
  padding: 16px 0 4px 0
  border-bottom: 1px solid rgba(133, 134, 165, 0.25)

.filterWrapper
  display: flex
  flex-direction: column
  row-gap: 10px

.filterLabel
  display: flex
  column-gap: 5px
  cursor: pointer

  svg
    color: $c-additional
    transition: all 0.2s

.filterLabel:hover
  svg
    color: #ffffff

.filterInput
  width: 150px
  font-size: 14px
  font-weight: 500
  border-bottom: 1px solid $c-additional
  background-color: transparent
