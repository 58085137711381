@import "/src/assets/styles/variables.sass"

.wrapper
  display: flex
  flex-direction: column
  align-items: center
  row-gap: 20px
  width: 500px
  padding: 25px 54px 30px

.message
  font-family: 'Roboto', sans-serif
  font-size: 20px
  line-height: 24px
  color: $c-accent
  text-align: center