.wrapper
  display: flex
  flex-direction: column
  padding-bottom: 30px
  padding-right: 16px

.returnLink
  display: flex
  align-items: center
  font-size: 18px
  margin-bottom: 36px

.returnLink:hover
  opacity: 0.8

.returnLink:active
  opacity: 0.6

.tariffList
  display: flex
  flex-wrap: wrap
  margin-top: 46px
  gap: 38px

.successMessage
  width: 500px
  padding: 30px 54px
  text-align: center

.infoBlock__wrapper
  display: flex
  align-items: center
  gap: 8px
  background-color: #2B3243
  padding: 24.5px 56px
  border-radius: 8px
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px -4px 10px rgba(0, 0, 0, 0.25)
  position: relative
  margin-bottom: 24px

.closeButton
  position: absolute
  top: 4px
  right: 4px
  background-color: transparent
  border: none
  color: white
  font-size: 20px
  width: 40px
  height: 40px
  cursor: pointer
  transition: transform 0.2s
  &:hover
    color: #B63940
  &:active
    transform: scale(0.9)