@import "src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.navLink
  position: relative
  display: flex
  align-items: center
  gap: 16px
  color: #8586A5
  width: 169px
  height: 62px
  padding: 0 16px


  > span
    font-size: 16px
    font-weight: 400
    line-height: normal
    font-style: normal

.navIcon

  width: 30px
  height: 30px

  > svg
      color: #8586A5
      width: 30px
      height: 30px

.navLink:hover
  > span
    color: white

  .navIcon
    > svg *
      fill: white

.navLink_active
  > span
    color: white

  .navIcon
    > svg *
      fill: white

.navLink_disabled
  @include disabled-link
