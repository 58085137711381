@import "/src/assets/styles/variables.sass"

.headCell
  min-width: 150px
  vertical-align: top

.botRow
  transition: all 0.15s

.botRow:hover
  color: $c-additional
  cursor: pointer

.botRow:active
  color: $c-additional-active

.selectBotType
  background-color: #2B3243
  border: none
  border-radius: 4px
  padding: 4px 8px
  cursor: pointer

.botAvatar
  width: 28px
  height: 28px
  border-radius: 50%

.cellSetting
  display: flex
  align-items: center
  gap: 16px

.btn
  min-width: 100px
  min-height: 35px

.selectBot
  background-color: #2B3243
  border: none
  border-radius: 4px
  padding: 4px 0
  cursor: pointer
  width: 100px



