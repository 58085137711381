.botMessageWrapper
  display: flex
  flex-direction: column
  align-items: center
  row-gap: 15px
  width: 310px
  font-family: 'Roboto', sans-serif
  font-size: 20px
  line-height: 24px
  text-align: center
