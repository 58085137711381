@import "src/assets/styles/variables.sass"

.progressWrapper
  width: 100%
  height: 90px
  background-color: $c-secondary
  border-radius: 8px
  padding: 17px 24px 0
  gap: 11px
  display: flex
  flex-direction: column

  &.mailing
    width: calc(27% + 47px + 16px)
    min-width: 383px

    @media screen and (min-width: 1900px) 
      width: 100%

.progressTitle
  font-size: 14px
  line-height: normal
  font-weight: 400
  color: $c-additional

.progressBold
  font-size: 16px
  font-weight: 500
  line-height: normal
  color: #ffffff

.progressBackground
  background-color: $c-additional
  height: 6px
  border-radius: 10px
  width: 100%

.progressBar
  background-color: $c-validation-green
  height: 6px
  border-radius: 10px

.progressParsers
  padding: 0
  display: flex
  gap: 5.5%

.progressParser
  position: relative
  list-style: none
  &::before
    position: absolute
    left: 0
    top: 4px
    content: ''
    height: 8px
    width: 8px
    border-radius: 2px
    background-color: #ffffff

  &:first-child::before
    position: absolute
    left: 0
    top: 4px
    content: ''
    height: 8px
    width: 8px
    border-radius: 2px
    background-color: $c-validation-green

.progressText
  padding: 0 0 0 16px
  font-size: 12px
  font-weight: 400
  line-height: normal
  color: $c-additional

.progressQuantity
  color: #ffffff
  font-size: 14px
  padding: 0 8px 0 0

@media screen and (min-width: 1900px)
  .progressWrapper
    width: 100%
    height: 110px
    gap: 19px
