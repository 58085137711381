@import "src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.wrapper
  background-color: #2B3243

  @media (max-width: 500px)
    position: absolute
    margin: 72px 0

.nav
  display: flex
  flex-direction: column
  align-items: center
  color: $c-light-grey
  height: 100vh

.navHr
  width: 11rem
  border: none
  color: #8586A5
  background-color: #8586A5
  height: 1px

.header__logoWrapper
  @media (max-width: 500px)
    display: none

.nav__links
  display: flex
  flex-direction: column
  align-items: center
  color: $c-light-grey
  flex-grow: 1
  margin-top: 16px

.nav__list
  position: relative
  display: flex
  flex-direction: column
  padding-right: 8px

.nav__links__btn
  @include green-button
  > span
    color: $c-primary-green
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50)

  &:hover
    > span
      color: $c-primary-green

.buttonOutWrapper
  margin-top: auto
  padding-bottom: 16px

  @media (max-width: 500px)
    height: 100%
    padding-top: 60px

.buttonOut
  display: flex
  align-items: center
  gap: 16px
  cursor: pointer
  width: 169px
  height: 62px
  padding: 0 12px

.buttonOut:hover
  > span
    color: white
  > button
    color: white
  .iconOut
    > svg *
      fill: white

.profilePage_btn
  color: #8586A5

.formContainer
  display: flex
  align-items: center
  gap: 20px
  padding: 0 8px
  cursor: pointer
  width: 169px
  height: 62px

.formContainer:hover
  > span
  color: white
  > button
    color: white
  .iconPartner
    > svg *
      fill: white

.partnerSystem
  display: flex
  color: #8586A5
  width: 98px
  height: 38px
  text-align: left

.header__menu_icon
  padding-top: 10px
  padding-left: 12px







