@import "/src/assets/styles/variables.sass"
  
@keyframes spin 
  from
    transform: rotate(0)

  to
    transform: rotate(359deg)


.spinner 
  width: 50px
  height: 50px
  border: 3px solid $c-primary-green
  border-top: 3px solid transparent
  border-radius: 50%
  animation: spin .5s linear 0s infinite

.loader__wrapper
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
