@import "src/assets/styles/mixins.sass"

.wrapper
  padding-top: 24px
  padding-bottom: 105px
  padding-left: 24px
  padding-right: 24px

.innerWrapper
  display: grid
  grid-template-areas: "title create-tariff" ". csv"
  align-items: center
  row-gap: 24px

  margin-bottom: 10px

.title
  @include header(20px)
  grid-area: title

.loaderWrapper
  display: flex
  justify-content: center
  align-items: center
  height: 30vh

.createButton
  grid-area: create-tariff
  justify-self: end
  padding: 12px
  max-width: 220px

.csvLink
  grid-area: csv
  justify-self: end

  text-decoration: underline
  color: $c-additional

.csvLink:hover
  opacity: 0.8

.csvLink:active
  opacity: 0.6

