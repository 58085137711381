@import "/src/assets/styles/variables.sass"

.label
  position: relative
  display: flex
  align-items: center
  column-gap: 8px
  cursor: pointer

.toggleInput
  position: absolute
  opacity: 0
  visibility: hidden

.toggle
  display: block
  position: relative
  width: 52px
  min-width: 52px
  height: 32px
  background-color: transparent
  border: 1px solid $c-additional
  border-radius: 100px

  &::before
    content: ""
    position: absolute
    display: block
    top: 50%
    left: 6%
    width: 24px
    height: 24px
    background: $c-additional
    border-radius: 50%
    transform: translateY(-50%)
    transition: all 0.2s

.toggleInput:checked + .toggle
  background-color: $c-additional

  &::before
    left: 48%
    background-color: $c-secondary

.toggleInput:hover + .toggle
  border: 2px solid $c-additional

.toggleInput:disabled + .toggle
  background-color: transparent
  border: 1px solid $c-disabled

  &::before
    background-color: $c-disabled