@import "src/assets/styles/mixins.sass"

.wrapper
  // padding-bottom: 105px

.innerWrapper
  display: grid
  grid-template-areas: "title create-tariff" ". csv"
  align-items: center
  row-gap: 24px

.buttonsWrapper
  display: flex
  justify-content: flex-end
  gap: 20px

.title
  @include header(20px)

  grid-area: title

.createButton
  grid-area: create-tariff
  justify-self: end

  max-width: 289px

.csvLink
  grid-area: csv
  justify-self: end

  text-decoration: underline
  color: $c-additional

.csvLink:hover
  opacity: 0.8

.csvLink:active
  opacity: 0.6

.loaderWrapper
  display: flex
  justify-content: center
  align-items: center
  height: 30vh

.botsstat__wrapper
  display: flex
  gap: 20px
  padding-top: 20px

.table__wrapper
  width: 50%
  