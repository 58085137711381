.wrapper
  display: flex
  flex-direction: column
  align-items: center
  row-gap: 12px

  padding: 6px
  width: 500px

.message
  font-family: 'Roboto', sans-serif
  font-size: 20px
  line-height: 24px