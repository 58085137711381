.examples__wrapper
  padding-top: 150px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

  @media (min-width: 1730px)
    padding-top: 170px

  @media (max-width: 900px)
    display: none

  @media (max-width: 500px)
    display: none

.examples__header
  width: 900px
  text-align: center
  font-size: 38px
  font-style: normal
  font-weight: 700
  line-height: normal
  color: #0176C9

  @media (min-width: 1730px)
    font-size: 46px

  // @media (max-width: 900px)
  //   width: 546px
  //   font-size: 28px
  //   font-weight: 500

.carouselContainer
  width: 1400px
  height: 600px
  padding-top: 60px
  cursor: grab

  @media (min-width: 1730px)
    padding-top: 70px

  // @media (max-width: 900px)
  //   padding-top: 24px
  //   width: 700px
  //   height: 473px

.centerImage
  position: relative
  border-radius: 30px
  height: auto
  margin-top: 60px
  display: block
  transition: width 0.5s ease-in-out

  