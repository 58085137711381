@import "../../../assets/styles/global.sass"

.button
  max-width: 413px
  max-height: 54px
  width: 100%
  padding: 12px 24px
  border: 2px solid transparent
  display: flex
  justify-content: center
  align-items: center
  border-radius: 4px
  background-color: $c-accent
  color: black
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.5)
  transform: scale(1)
  transition: all 0.3s ease-out


  &:hover
    transform: scale(1.05)
    transition: all 0.3 ease-out
    background-color: #d33139

  &:active:enabled
    background-color: $c-additional-active
    border: 2px solid transparent !important
    box-shadow: none

  &:disabled
    cursor: default
    box-shadow: none

.innerText
  padding: 0px 12px
  font-family: "Inter", sans-serif
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 16px
  color: white

.accent
  background-color: $c-accent
  color: white

  &:hover:enabled
    border: 2px solid $c-additional

  &:disabled
    .innerText
      opacity: 0.5

  &:active:enabled
    background-color: $c-accent-active

.profile
  max-width: 310px

.profileModalBtn
  max-width: 214px

.profileCancelBtn
  max-width: 214px
  background-color: #8586A5

  &:hover
    transform: scale(1.05)
    transition: all 0.3 ease-out
    background-color: #7a7c98


.small
  max-width: 122px
  color: white

  &:disabled
    .innerText
      opacity: 0.6

.additional
  &:disabled
    .innerText
      opacity: 0.4

.buy
  width: 224px
  padding: 16px 0px
  display: flex
  justify-content: center
  align-items: center
  gap: 10px
  background: #B63940
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.50)
  color: white
  cursor: pointer

  &:hover
    background: #d33139
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px

  &:active
    background: #6c0d11
    color: lightgrey
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px

.adminChangeTariff
  padding: 6px 0
  max-width: 100px
  display: flex
  justify-content: center
  align-items: center
  background: #8586A5
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.15)
  color: white
  cursor: pointer

  &:hover
    transform: scale(1)
    background: #7a7c98
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px

.adminDeleteTariff
  padding: 6px 0
  max-width: 100px
  display: flex
  justify-content: center
  align-items: center
  background: #B63940
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.15)
  color: white
  cursor: pointer

  &:hover
    transform: scale(1)
    background: #d33139
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px
