@import "/src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"
.graphWrapper
  height: 100%
  width: 100%
  display: flex

.loader
  display: flex
  justify-content: center
  align-items: center
  height: 15em
  align-self: center
  margin: 0 auto

.textNoData
  align-self: center
  margin: 0 auto
  color: $c-wild-blue
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal
  text-align: center

  @media screen and (min-width: 1900px)
    font-size: 20px

.barChart
  background: #2B3243
  border-radius: 30px
  padding: 30px

.loaderWrapper
  display: flex
  justify-content: center
  align-items: center
  height: 30vh
