@import "src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"


.wrapper
  background-color: #2B3243

.adminBar
  display: flex
  flex-direction: column
  align-items: center
  color: $c-light-grey
  height: 100vh

.header__logoWrapper
  padding-bottom: 16px

.adminBar__links
  display: flex
  flex-direction: column
  align-items: center
  color: $c-light-grey
  flex-grow: 1

.adminBar__link
  position: relative
  display: flex
  flex-direction: column
  padding-right: 8px

.buttonOutWrapper
  margin-top: auto
  padding-bottom: 16px

.buttonOut
  display: flex
  align-items: center
  gap: 16px
  cursor: pointer
  width: 169px
  height: 62px
  padding: 0 12px

.buttonOut:hover
  > span
    color: white
  > button
    color: white
  .iconOut
    > svg *
      fill: white

.profilePage_btn
  color: #8586A5

.formContainer
  display: flex
  align-items: center
  gap: 20px
  padding: 0 8px
  cursor: pointer
  width: 169px
  height: 62px

.formContainer:hover
  > span
  color: white
  > button
    color: white
  .iconPartner
    > svg *
      fill: white

.partnerSystem
  display: flex
  color: #8586A5
  width: 98px
  height: 38px
  text-align: left
