@import "src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.underline
  position: absolute
  content: ""
  display: block
  bottom: -8px
  height: 2px
  background-color: #ffffff
  transition: all 0.3s ease-out