@import '../../../node_modules/material-icons/iconfont/material-icons.scss'
@import "./variables.sass"
// @import "./fonts.sass"
@import-normalize

html
  scroll-behavior: smooth

html, body
  margin: 0
  padding: 0
  font-family: 'Roboto', sans-serif
  font-size: 16px
  font-weight: 400
  line-height: 20px
  color: white
  background: $c-background
  cursor: default

a
  color: inherit
  text-decoration: none

*
  box-sizing: border-box
  font-family: inherit
  scrollbar-width: none
  scrollbar-color: $c-secondary $c-wild-blue

button, input, optgroup, select, textarea
  margin: 0
  padding: 0
  font-family: inherit
  font-size: 100%
  font-weight: inherit
  line-height: inherit
  color: inherit

button
  cursor: pointer
  background: transparent

input, button
  outline: none
  border: none

ul
  padding: 0
  margin: 0
  list-style-type: none

h1, h2, h3, h4, h5, h6, figure, p, pre
  font-weight: normal
  font-size: inherit
  margin: 0
  padding: 0

.visually-hidden
  position: absolute
  width: 1px
  height: 1px
  margin: -1px
  border: 0
  padding: 0
  white-space: nowrap
  clip-path: inset(100%)
  clip: rect(0 0 0 0)
  overflow: hidden

.center
  display: flex
  justify-content: center
  align-items: center

.leaflet-geosearch-bar
  color: black
  z-index: 3 !important

.leaflet-bottom, .leaflet-right, .leaflet-left, .leaflet-top, .leaflet-control
  z-index: 3 !important

.leaflet-pane
  z-index: 2 !important

.leaflet-attribution-flag
  width: 0 !important
  height: 0 !important

.material-icons
  user-select: none

::-webkit-scrollbar
  display: none
  width: 10px

::-webkit-scrollbar-track
  background: $c-wild-blue
  border-radius: 100px

::-webkit-scrollbar-thumb
  background-color: $c-secondary
  border-radius: 100px
  width: 6px
