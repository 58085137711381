@import 'src/assets/styles/global.sass'

.markerButton
  border-top-right-radius: 0
  border-top-left-radius: 0

.radiusSelect
  display: flex
  gap: 10px
  flex-wrap: wrap

.mapWrapper
  margin-bottom: 10px

.fieldWrapper
  margin-top: 40px