@import "/src/assets/styles/variables.sass"
  
.toolsCategories
  display: flex
  padding-bottom: 8px

.toolsCategory
  display: flex
  align-items: center
  cursor: pointer
  padding: 16px
  color: var(--Gray, #8586A5)
  font-size: 16px
  position: relative

.toolsCategory__active
  color: white
  font-size: 16px
  text-decoration: underline
  text-decoration-color: var(--Red, #B63940)
  text-underline-offset: 5px
  text-decoration-thickness: 2px

