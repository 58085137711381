@import "src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.sectionTableTasks
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25)
  height: 100%

.title
  margin-bottom: 28px
  margin-top: 48px
  padding-left: 12px
  font-family: 'Roboto', sans-serif
  font-size: 28px
  font-weight: 600
  line-height: 20px

.taskDetailLink
  color: $c-additional
  text-decoration: underline

.taskDetailLink:hover
  opacity: 0.8

.taskDetailLink:active
  opacity: 0.6

.taskControls
  display: flex
  align-items: center
  column-gap: 10px

.taskButton
  @include tool-button
  display: flex
  align-items: center

.statusCell_success
  color: green

.statusCell_in_processing
  color: yellow

.statusCell_in_waiting
  color: blue

.statusCell_failed
  color: $c-error

.textNoData
  align-self: center
  margin: 0 auto
  color: $c-wild-blue
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal
  text-align: center
  
  @media screen and (min-width: 1900px)
    font-size: 20px
