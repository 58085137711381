@import "src/assets/styles/variables.sass"

.tooltip
  position: relative
  
.tooltipWrapper
  position: absolute
  top: 0
  right: 0

.tooltipArrow
  width: 17px
  height: 23px
  position: absolute
  transform: translateX(0) translateY(-50%)
  top: 25px
  stroke: #8586A5

.rightTooltipArrow
  transform: translateX(0) translateY(-50%)
  left: $position-tooltip-arrow

.leftTooltipArrow
  transform: translateX(0) translateY(-50%) rotate(180deg)
  left: calc(0px - $position-tooltip-arrow - $position-left-tooltip)

.tooltipArrowCover
  width: 17px
  height: 23px
  position: absolute
  top: 25px
  fill: $c-background
  z-index: 4

.rightArrowCover
  transform: translateX(0) translateY(-50%)
  left: $position-tooltip-arrow-cover

.leftArrowCover
  transform: translateX(0) translateY(-50%) rotate(180deg)
  left: calc(0px - $position-left-tooltip - $position-tooltip-arrow-cover)

.tooltipTip
  position: absolute
  border-radius: 4px
  padding: 12px
  color: #E7E7E7
  background: $c-background
  font-size: 12px
  font-weight: 400
  line-height: normal
  z-index: 3
  border: 1px solid $c-additional

.rightTooltipTip
  transform: translateX(16px)

.leftTooltipTip
  transform: translateX(calc(-100% - $position-left-tooltip))

.smallTooltipTip
  width: max-content

.bigTooltipTip
  width: 244px
