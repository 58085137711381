@import "src/assets/styles/global.sass"
@import "src/assets/styles/mixins.sass"

.pageWrapper
  padding: 0 16px 0 0

.wrapper
  display: flex
  margin: 7px 0 20px
  justify-content: space-between

.parsersWrapper
  width: 39%

.headerWrapper
  margin-bottom: 30px


.notAllowedParser
  color: $c-error

.tabs
  position: relative
  display: flex
  justify-content: space-between
  font-weight: 600
  margin-bottom: 37px

.navTabs
  width: 100%
  border-bottom: 1px solid $c-additional !important

.link
  color: $c-additional !important
  padding: 12px 16px !important

  &:hover
    color: #ffffff !important

.linkActive
  background-color: transparent !important
  color: white !important
  border-color: $c-additional $c-additional $c-background !important

@media screen and (max-width: 1228px)
  .wrapper
    gap: 30px

.info
  display: flex
  justify-content: space-between