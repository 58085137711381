@import "/src/assets/styles/variables.sass"
@import "/src/assets/styles/mixins.sass"

.tariffsDirection
  @include header(20px)
  display: block
  padding-left: 12px
  font-size: 12px
  line-height: 24px
  font-weight: 400
  text-decoration: underline
  color: #8586A5

  @media screen and (min-width: 2560px)
    font-size: 20px

.tariffsDirection__link
  text-decoration: underline

  &:hover
    opacity: 0.8

  &:active
    opacity: 0.6

.info
  display: flex
  align-items: center
  column-gap: 12px
  padding-left: 12px
  font-family: 'Roboto', sans-serif
  font-size: 20px
  font-weight: 600


.infoAccent
  font-size: 28px
  margin-bottom: 4px

.infoButton
  display: block
  padding-left: 12px
  font-size: 12px
  line-height: 12px
  font-weight: 400
  text-decoration: underline
  color: $c-additional

.infoButton:hover
  opacity: 0.8

.infoButton:active
  opacity: 0.6

.tariffDetails
  width: 500px
  padding: 30px 44px 50px

.tariffTitle
  margin-bottom: 45px
  font-size: 20px
  font-family: 'Roboto', sans-serif
  font-weight: 700
  text-align: center

.tariffCharacteristics
  display: flex
  gap: 20px
  margin-bottom: 50px

.tariffColumn
  padding: 0
  display: flex
  flex-direction: column
  gap: 24px

.tariffField
  list-style: none
  padding: 0
  font-weight: 500
  line-height: normal
  font-size: 16px

.tariffValue
  list-style: none
  padding: 0
  font-size: 16px
  letter-spacing: normal

.autoRenewal
  display: flex
  align-items: center
  justify-content: space-between

  margin-bottom: 50px

  @include header(16px)

.error
  margin-bottom: 50px

  text-align: center
  color: $c-error
