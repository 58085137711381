@import "/src/assets/styles/mixins.sass"

.registrationForm
  @include form

.title
  @include title

.successMessage
  display: flex
  flex-direction: column
  align-items: center
  row-gap: 15px
  width: 500px
  font-family: 'Roboto', sans-serif
  font-size: 20px
  line-height: 24px
  padding: 30px 43px 43px

.error
  @include error

.btnSucess
  width: 450px

.loaderContainer
  display: flex
  justify-content: center

@media screen and (max-width: 450px)
  .registrationForm
    width: 100%
