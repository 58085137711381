@import "/src/assets/styles/mixins.sass"
@import "/src/assets/styles/global.sass"

.header
  @include header(20px)
  display: flex
  align-items: center
  margin-bottom: 20px

.wrapper
  display: flex
  justify-content: center

.mainData
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: 80px

.columnWrapper
  display: flex

// .toggleColumn
//   align-self: flex-start
//   gap: 25px

.rowWrapper
  display: flex
  gap: 130px

.roleWrapper
  display: flex
  justify-content: center
  width: 660px
  height: 220px
  padding: 24px
  gap: 80px
  border: 1px solid $c-additional
  border-radius: 8px

.balanceHeader
  margin-bottom: 20px

.balanceWrapper
  display: flex
  justify-content: center
  gap: 80px
  border: 1px solid $c-additional
  border-radius: 8px
  width: 660px
  height: 220px
  padding: 24px

.currentBlanceWrapper
  display: flex
  flex-direction: column
  align-items: center
  gap: 16px
  width: 200px

.balance
  display: flex
  align-items: center
  gap: 8px

.currentBlance
  @include header(28px)
  color: $c-additional
  padding-top: 16px

.addBalance
  display: flex
  flex-direction: column
  gap: 16px
  width: 300px

.inputBalance
  max-height: 50px
  width: 100%
  display: flex
  position: relative
  outline: 1px solid $c-additional
  border-radius: 4px
  padding: 16px 8px
  background-color: transparent
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
  border-radius: 4px
  font-size: 16px
  line-height: 24px
  color: $c-support-text
  max-width: 413px
  &:hover
    outline-width: 2px
  &:focus
    outline-width: 2px
  &:active
    outline-width: 2px

.dropdown
  width: 250px
  height: 56px

.currentTariff
  display: flex
  flex-direction: column
  gap: 16px

.diccountWrapper
  display: flex
  justify-content: center
  gap: 80px
  border: 1px solid $c-additional
  border-radius: 8px
  width: 660px
  height: 220px
  padding: 24px

.currentDiscount
  display: flex
  flex-direction: column
  align-items: center
  gap: 16px
  width: 200px
