@import "/src/assets/styles/variables.sass"
@import "/src/assets/styles/mixins.sass"
  
.checkbox
  display: flex
  align-items: center
  column-gap: 10px

  font-size: 14px

  cursor: pointer

.checkboxInput
  position: absolute
  z-index: -1
  opacity: 0

.checkboxMark
  position: relative

  display: flex
  justify-content: center
  align-items: center
  
  width: 18px
  height: 18px

  background-color: transparent

  border: 2px solid $c-additional
  border-radius: 2px

  transition: all 0.2s

  svg
    color: #ffffff

.checkboxInput:checked + .checkboxMark
  background-color: $c-additional
  background-image: url("/assets/images/icons/input-check-icon.svg")
  background-position: center center
  background-repeat: no-repeat
    

.checkboxInput:hover + .checkboxMark
  transform: scale(1.2)

.checkboxInput:disabled + .checkboxMark
  transform: scale(1)
  background-color: transparent
  border-color: $c-disabled

  svg
    color: $c-disabled