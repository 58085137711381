.toTopButton
  position: fixed
  bottom: 135px
  right: 60px
  background-color: #054E9B
  color: #fff
  border: none
  cursor: pointer
  display: none
  width: 46px
  height: 46px
  padding: 8px
  border-radius: 30px

  @media (max-width: 500px)
    bottom: 120px
    right: 20px


.toTopButton:hover
  background-color: #0056b3