@import "src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.form
  display: flex
  flex-direction: column
  margin: 20px 0 0

.instruction__link
  font-size: 16px
  font-weight: 400
  line-height: 1.5
  text-decoration: underline
  color: #8586A5
  cursor: pointer
  margin-bottom: 24px

.inputGroup
  display: flex
  align-items: center
  gap: 30px

.fieldValidationBorder
  padding: 0 0 40px 47px
  border-left: 1px dashed $c-wild-blue
  position: relative
  margin-top: 16px

  &::before
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%238586A5" fill="%231A2235"/></svg>')
    position: absolute
    top: 0
    left: 0
    transform: translate(-53%, -20%)

  &:first-of-type
    padding-top: 0

  &:last-of-type
    margin: 0 0 40px
    padding-bottom: 0

    &::after
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%238586A5" fill="%231A2235"/></svg>')
      position: absolute
      bottom: 0
      left: 0
      transform: translate(-53%, 50%)

  &.success
    border-left: 1px dashed $c-validation-green

    &::before
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%23399830" fill="%23399830"/></svg>')

    &:last-of-type
      &::after
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%23399830" fill="%23399830"/></svg>')

  &.errors
    border-left: 1px dashed $c-accent

    &::before
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%23B63940" fill="%23B63940"/></svg>')

.fieldWrapper
  position: relative
  margin-top: 16px

.header
  font-size: 18px
  font-weight: 500
  margin-bottom: 24px
  width: 90%

.groupsHeader
  display: flex
  justify-content: space-between

.createFieldBtn
  display: flex
  align-items: center
  gap: 5px

.groupsInputsWrapper
  display: flex
  flex-direction: column
  gap: 10px

.groupsInput
  position: relative

// .groupsInput
//   position: relative
//   display: flex
//   justify-content: space-between
.infoIcon
  position: absolute
  top: 0
  right: 0

.minusIcon
  position: absolute
  top: 50%
  transform: translateY(-50%)
  right: -30px

.activities
  display: flex
  gap: 25px

.fieldQty
  display: flex
  align-items: center
  gap: 52px

.inputQty
  max-height: 56px
  display: flex
  position: relative
  padding: 16px 0
  background-color: transparent
  font-size: 16px
  text-align: center
  line-height: 24px
  color: $c-support-text
  width: 30px

.inputDate
  max-height: 56px
  width: calc(100% - 30px)
  display: flex
  position: relative
  outline: 1px solid $c-additional
  border-radius: 4px
  padding: 16px
  background-color: transparent
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
  border-radius: 4px
  font-size: 16px
  line-height: 24px
  text-align: center
  color: $c-support-text
  max-width: 413px
  cursor: pointer
  &:hover
    outline-width: 2px
  &:focus
    outline-width: 2px
  &:active
    outline-width: 2px

// ::-webkit-calendar-picker-indicator 
//     filter: invert(1)
//     cursor: pointer

input[type="date"]::-webkit-calendar-picker-indicator
  background: transparent
  bottom: 0
  color: transparent
  cursor: pointer
  height: auto
  left: 0
  position: absolute
  right: 0
  top: 0
  width: auto

.buttonGroup
  display: flex
  padding-top: 12px
  width: 30%
  cursor: pointer

.customBtnSecondary
  color: #fff
  border-color: $c-additional

.customBtnSecondary:hover
  background-color: darken($c-additional, 10%)
  border-color: darken($c-additional, 10%)

.selectedTasks
  max-width: 610px
  min-height: 164px
  overflow-y: scroll
  margin-top: 15px
  border-radius: 4px
  border: 1px solid $c-additional

.errorMessage
  font-size: 12px
  font-family: Roboto
  font-style: normal
  font-weight: 400
  line-height: 16px
  color: $c-error
  margin-top: 6px
  margin-left: 15px

.modal-body
  background-color: $c-additional !important

.incDecButton
  cursor: pointer
  font-size: 16px

.hidden
  visibility: hidden
  opacity: 0

.disabled
  opacity: .5
  cursor: unset
