$c-primary-green: #82c097
$c-active-green: #3ae473
$c-validation-green: #399830
$c-transparent-green: rgba(130, 192, 151, 0.13)

$c-primary-grey: #202020
$c-primary-red: #e1665f

$c-background: #1A2235

$c-light-grey: #666b67
$c-wild-blue: #8586A5

$c-main: #1A2235
$c-secondary: #2B3243

$c-accent: #B63940
$c-accent-active: #942D33

$c-additional: #8586A5
$c-additional-active: #7476AC

$c-error: #B3261E
$c-support-text: #C4C4D0
$c-disabled: #303649

$position-tooltip-arrow: 8px
$position-tooltip-arrow-cover: 8.5px
$position-left-tooltip: 32px
