@import "src/assets/styles/variables.sass"

.form
  width: 400px
  background-color: #1A2235
  padding: 20px
  border-radius: 8px
  text-align: center
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 9999
  
