@import "../../../assets/styles/global.sass"

.btn_transparent
  max-width: 413px
  max-height: 54px
  diplay: flex
  justify-content: center
  align-items: center
  border: 1px solid #B63940
  border-radius: 8px
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s
  &:hover
    background-color: #B63940
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px -4px 10px rgba(0, 0, 0, 0.25)
    transition-duration: 0.5s
  &:active
    background-color: #B63940
    transition-duration: 0.1s
    transform: scale(0.99)

.standart
  min-width: 180px
  min-height: 40px

.blue
  border: 1px solid #007bff
  min-width: 180px
  min-height: 40px
  &:hover
    background-color: #007bff
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px -4px 10px rgba(0, 0, 0, 0.25)
    transition-duration: 0.5s
  &:active
    background-color: #007bff
    transition-duration: 0.1s
    transform: scale(0.99)

.grey
  border: 1px solid grey
  min-width: 180px
  min-height: 40px
  &:hover
    background-color: grey
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px -4px 10px rgba(0, 0, 0, 0.25)
    transition-duration: 0.5s
  &:active
    background-color: grey
    transition-duration: 0.1s
    transform: scale(0.99)


.big
  min-width: 420px
  min-height: 40px

.fat
  min-width: 224px
  min-height: 48px