@import '../../../assets/styles/global'

.input
  &:-webkit-autofill,
  &:-webkit-autofill:hover, 
  &:-webkit-autofill:focus, 
  &:-webkit-autofill:active
      -webkit-background-clip: text
      -webkit-text-fill-color: $c-support-text
      caret-color: $c-support-text

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button 
    -webkit-appearance: none
    margin: 0

  &::-ms-reveal
    display: none

  &[type=date]
    &::-webkit-calendar-picker-indicator 
      filter: invert(1)

  &[type=number]
    -moz-appearance: textfield

.inputContainer 
  display: flex
  flex-direction: column
  font-family: 'Roboto', sans-serif
  font-weight: 400
  font-size: 16px
  line-height: 24px
  color: $c-support-text
//   max-width: 413px
  width: 100%

  @media screen and (min-width: 1900px)
    max-width: unset

.inputWrapper
  max-height: 56px
  display: flex
  position: relative
  outline: 1px solid #FFF
  border-radius: 15px

  &.disabled
    outline-color: $c-disabled
    color: $c-disabled
    
    +.hintMessage
      color: $c-disabled

  &.focus
    outline-width: 2px

  &:hover:not(.disabled):not(.temporaryDisabled)
    outline-width: 2px  

.input
  width: 100%
  padding: 12px 14px
  background-color: transparent
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
  border-radius: 15px

  transition: all .1s

  &:focus~.placeholder, &:not(:placeholder-shown) ~ .placeholder, &:-webkit-autofill ~ .placeholder
    transform: scale(0.75) translateY(-54px)
    left: 3px

.placeholder
  position: absolute
  padding: 0 8px
  left: 3px
  bottom: 11px
  cursor: text
  color: white

  transition: .1s
  
.error
  border-color: #FFB4AB !important
  outline-color: #FFB4AB !important
  color: #FFB4AB !important


  + .placeholder, .input
    color: #FFB4AB !important

.endIcon, .startIcon
  padding: 0 16px
  display: flex
  justify-content: center
  align-items: center
  
  transition: all .1s

  &:active
    ~.placeholder
      transform: scale(0.75) translateY(-38px)
      left: 3px

.endIcon
  padding-left: 0
  border-left: none
  border-top-right-radius: 4px
  border-bottom-right-radius: 4px

.startIcon
  order: -1
  padding-right: 0
  border-right: none
  border-top-left-radius: 4px
  border-bottom-left-radius: 4px

  & + .placeholder
    left: 50px

.errorMessage, .hintMessage 
  font-size: 12px
  padding-top: 4px
  display: flex
  justify-content: flex-start
  padding-left: 12px
  align-self: start
  line-height: 1.2

.errorMessage
  color: #FFB4AB
