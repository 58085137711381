@import "/src/assets/styles/variables.sass"

@mixin button-link
  cursor: pointer
  margin: 5px
  background: $c-light-grey
  display: flex
  justify-content: center
  align-items: center
  height: 40px
  width: 170px
  font-size: 13px
  border-radius: 5px
  transition: all .15s

  &__active
    color: $c-primary-green
    background: #474747
    border: 1px solid $c-primary-green

@mixin button
  cursor: pointer
  margin: 5px
  background: $c-transparent-green
  padding: 4px 35px
  font-size: 14px
  border-radius: 5px
  > button, span
    color: $c-primary-green

@mixin green-button
  display: flex
  width: 385px
  align-items: center
  justify-content: center
  margin: 0
  padding: 1rem 0

  font-size: 13px
  color: white
  border: 1px solid #B63940
  border-radius: 4px
  cursor: pointer
  transition: all 0.3s ease-in-out

  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.5)

  > span
    margin-right: 10px
    transition: color .15s

  &:active
    opacity: 0.6

  &:hover
    background-color: #B63940

@mixin submit-button
  display: flex
  width: 310px
  align-items: center
  justify-content: center
  margin: 0
  padding: 16px 0

  font-size: 15px
  font-weight: 500
  color: white
  border: 1px solid #B63940
  border-radius: 8px
  cursor: pointer
  transition: all 0.3s ease-in-out

  box-shadow: 0px 0px 20px 0px rgba(255, 0, 14, 0.1)

  > span
    margin-right: 10px
    transition: color .15s

  &:active
    opacity: 0.6

  &:hover
    background-color: #B63940
    box-shadow: 0px 0px 20px 0px rgba(255, 0, 14, 0.1)

  &:disabled 
    background-color: transparent
    border: 1px solid #ccc
    color: #999
    cursor: not-allowed
    box-shadow: none

@mixin tool-button
  span
    color: #bbb6b6
  &:hover
    color: $c-additional

  &:active
      opacity: 0.6

@mixin enterButton
  @include green-button

  justify-content: center
  column-gap: 5px

  padding: 0.8rem

  font-size: 16px

  border-radius: 10px

  svg
    color: $c-light-grey
    transition: 0.2s color ease-out

  &:hover
    svg
      color: $c-primary-green

  &:disabled
    opacity: 0.6

@mixin close-button
  width: 24px
  height: 24px
  border: none
  background-color: transparent
  cursor: pointer
  transition: 0.2s all

  &:before,
  &:after
    content: ""
    position: absolute
    top: 16px
    left: -5px
    height: 16px
    width: 2px
    background-color: white
    transform: translateX(-50%)
    transition: 0.2s all ease-in-out

  &:before
    transform: rotate(45deg)

  &:after
    transform: rotate(-45deg)

  &:hover
    &:before,
    &:after
      background-color: $c-accent

  &:active
    transform: scale(0.9)