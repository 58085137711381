.wrapper
  display: flex
  flex-direction: column
  gap: 80px
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal
  padding: 12px 16px

.documents__link
  // border-bottom: 1px solid white
  &:hover
    color: #8586A5
    border-color: #8586A5
  &:active
    color: #7476AC
    border-color: #7476AC

