@import "/src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.wrapper
  display: flex
  justify-content: center
  flex-direction: column
  gap: 20px
  height: 772px

  @media (max-width: 500px)
    height: auto

.infoBanner__wrapper
  display: flex
  gap: 16px

  @media (max-width: 500px)
    display: none

.infoBanner
  width: 50%

.HeadLineWrapper
  display: flex
  gap: 20px
  height: 71vh

  @media (max-width: 500px)
    height: auto
    flex-direction: column-reverse


.wrapperLeftBlock
  display: flex
  flex-direction: column
  gap: 20px
  width: 76%
  height: 100%

  @media (max-width: 500px)
    width: 100%

.title
  margin-bottom: 24px
  font-family: 'Roboto', sans-serif
  font-size: 20px
  font-weight: 700
  line-height: 24px

.info
  height: calc(100% - 40px)
  // flex: 1

  @media (max-width: 500px)
    display: none

.loaderWrapper
  display: flex
  justify-content: center
  align-items: center



