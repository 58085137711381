.contract
  margin-bottom: 120px
  padding-top: 66px
  padding-bottom: 80px
  padding-left: 80px
  padding-right: 80px
  background-color: #C4C4D0

.contract__list_one
  display: grid
  grid-template-columns: 1fr
  gap: 3rem
  margin: 0
  padding: 0

.contract__list_one li
  &:not(:last-child)
    margin-bottom: 40px


.contract__list_one li h3
  margin-bottom: 24px
  font-family: 'Montserrat', sans-serif
  font-size: 18px
  font-style: normal
  font-weight: 600
  line-height: 22px
  color: #000
  text-align: center

.contract__list_one li h4
  margin-bottom: 24px
  font-family: 'Montserrat', sans-serif
  font-size: 18px
  font-weight: 600
  line-height: 22px
  text-align: start
  color: #000

.contract__list_one li p
  font-family: 'Montserrat', sans-serif
  font-size: 16px
  line-height: 20px
  color: #000

  &:not(:last-child)
    margin-bottom: 24px


.contract__header
  max-width: 460px
  margin: 0 auto
  margin-bottom: 3rem
  font-family: 'Montserrat', sans-serif
  font-size: 20px
  font-style: normal
  font-weight: 600
  line-height: 24px
  text-align: center
  color: #000

.contract__essentials
  max-width: 235px
  font-family: 'Montserrat', sans-serif
  font-size: 18px
  font-weight: 500
  line-height: 22px
  color: #000