.partners__wrapper
  width: 100%
  height: 855px
  background: white

  @media (min-width: 1900px)
    height: 764px

  @media (max-width: 900px)
    height: 845px

  @media (max-width: 500px)
    display: none

.partners__header
  color: #1A2235
  text-align: center
  padding-top: 60px
  font-size: 38px
  font-style: normal
  font-weight: 700
  line-height: normal

  @media (min-width: 1900px)
    padding-top: 74px
    font-size: 46px

  @media (max-width: 900px)
    padding-top: 50px
    font-size: 28px
    font-weight: 500

.partners_content
  padding: 50px 40px
  user-select: none
  display: grid
  grid-template-columns: repeat(6, 1fr)
  grid-template-row: repeat(5, 1fr)
  grid-column-gap: 20px
  grid-row-gap: 50px

  @media (min-width: 1900px)
    padding: 50px
    grid-template-columns: repeat(8, 1fr)
    grid-template-row: repeat(4, 1fr)

  @media (max-width: 900px)
    padding: 20px
    grid-template-columns: repeat(4, 1fr)
    grid-template-row: repeat(7, 1fr)
    grid-row-gap: 30px

.partners_logo
  display: flex
  justify-content: center
  align-items: center

.img
  width: 210px

  @media (max-width: 900px)
    width: 167px