@import "/src/assets/styles/variables.sass"

@mixin form
  display: flex
  flex-direction: column
  row-gap: 30px

  width: 400px

  text-align: center

@mixin title
  font-size: 20px
  font-family: 'Roboto', sans-serif
  font-weight: 700
  line-height: 24px


@mixin inputLabel
  display: block

@mixin error
  font-size: 16px
  color: $c-error
  text-align: center
  font-weight: 600

  margin-top: 5px