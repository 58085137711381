@import "/src/assets/styles/variables.sass"
  
@mixin text-input
  width: 100%
  padding: 0.8rem
  background-color: #1A2235
  border-radius: 4px
  border: 1px solid  #8586A5
  color: white
  &:focus
    @include element-focus

@mixin element-focus
  outline: 2px solid $c-additional