@import "/src/assets/styles/mixins.sass"
@import "/src/assets/styles/variables.sass"
  
.loginForm
  @include form

.title
  @include title

.inputLabel
  @include inputLabel

.input
  @include text-input

.error
  @include error

.resetPasswordLink
  display: block

  width: fit-content

  margin-top: 8px
  margin-left: auto

  font-size: 14px

.resetPasswordLink:hover
  opacity: 0.8
  
.resetPasswordLink:active
  opacity: 0.6

.loaderContainer
  display: flex
  justify-content: center

@media screen and (max-width: 450px)
  .loginForm
    width: 100%