.resetPassForm
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  padding-top: 68px

.resetPassHeader
    font-size: 20px
    font-weight: 700
    line-height: 24px

.resetPassInputContainer
  width: 500px
  display: flex
  flex-direction: column
  align-items: center
  gap: 24px
  padding: 20px 44px 32px
  background: #2B3243
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5)
  border-radius: 8px

.resetPassForm label
  width: 100%
  padding: 6px 0
  outline: 1px solid #8586A5
  border-radius: 4px
  background: #2B3243
  
.resetPassInput
  color: white
  padding: 12px
  width: 100%
  border-radius: 4px
  background: #2B3243

.resetPassBtn
  diplay: flex
  justify-content: center
  align-items: center
  width: 100%
  padding: 18px 0
  border: 1px solid #B63940
  border-radius: 4px
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s
  &:hover
    background-color: #B63940
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px -4px 10px rgba(0, 0, 0, 0.25)
    transition-duration: 0.5s
  &:active
    background-color: #B63940
    transition-duration: 0.1s
    transform: scale(0.99)
