@import "/src/assets/styles/variables.sass"
@import "/src/assets/styles/mixins.sass"
  
.roundRadio
  display: flex
  align-items: center

  column-gap: 10px

  font-size: 16px

  cursor: pointer

.radioInput
  position: absolute
  z-index: -1
  opacity: 0

.radioMark
  position: relative

  display: block
  
  width: 20px
  height: 20px

  border: 2px solid $c-additional
  border-radius: 50%

  background-color: transparent

  transition: all 0.2s

.radioInput:hover + .radioMark
  transform: scale(1.2)


.radioInput:checked + .radioMark::before
  content: ""
  display: block

  position: absolute
  top: 50%
  left: 50%
  transform: translateY(-50%) translateX(-50%)

  width: 10px
  height: 10px

  background-color: $c-additional

  border-radius: 50%

.radioInput:disabled + .radioMark
  transform: scale(1)
  border-color: $c-disabled

.radioInput:disabled + .radioMark::before
  background-color: $c-disabled

.radioInput:focus + .radioLabel
  @include element-focus