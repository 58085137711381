@import "/src/assets/styles/mixins.sass"

.input,.button
  max-width: 310px

.header
  @include header(20px)
  display: flex
  justify-content: center

.wrapper
  padding: 40px 60px

.formWrapper
  width: 310px
  display: flex
  flex-direction: column
  gap: 30px