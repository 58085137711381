@import "/src/assets/styles/global.sass"
@import "/src/assets/styles/mixins.sass"

.wrapper
  display: flex
  gap: 100px
  justify-content: center
  padding-top: 80px

.header
  @include header(20px)
  display: flex
  align-items: center
  margin-bottom: 16px

.statusHeader
  margin-bottom: 23px

.avatarWrapper
  display: flex
  flex-direction: column
  align-items: center
  gap: 8px

.avatar
  position: relative

.botAvatar
  width: 200px
  height: 200px
  border-radius: 50%
  border: 3px solid #B63940

.avatarInput
  display: none

.avatarlabel
  position: absolute
  bottom: 0
  right: 0
  cursor: pointer

.pictureInfo
  color:  #8586A5
  font-size: 12px

.settingsWrapper
  display: flex
  gap: 100px

.settings
  display: flex
  flex-direction: column
  gap: 24px

.statuses
  margin-right: 100px
  display: flex
  flex-direction: column
  gap: 24px

.status
  color: $c-additional
  padding-bottom: 10px
  border-bottom: 1px solid $c-additional

.input
  width: 600px

.button
  height: 50px
  width: 600px
  margin-top: 40px

.loader
  display: flex
  align-items: center
  justify-content: center