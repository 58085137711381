.review__wrapper
  padding-top: 150px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  user-select: none

  @media (min-width: 1730px)
    padding-top: 170px

  @media (max-width: 900px)
    padding-top: 58px

  @media (max-width: 500px)
    padding-top: 56px

.review__header
  text-align: center
  font-size: 38px
  font-style: normal
  font-weight: 700
  line-height: normal

  @media (min-width: 1730px)
    font-size: 46px

  @media (max-width: 900px)
    padding-top: 28px
    font-size: 28px
    font-weight: 500

  @media (max-width: 500px)
    padding-top: 24px
    font-size: 24px
    font-weight: 500

.ReviewSlider__containeer
  width: 100%
  height: 600px

.review__containeer
  display: flex
  gap: 20px
  padding-top: 50px

  @media (min-width: 1730px)
    gap: 40px
    padding-top: 70px

  @media (max-width: 900px)
    padding-top: 24px

  @media (max-width: 500px)
    padding-top: 12px

.review__block
  width: 440px
  height: 305px
  padding: 30px 20px
  display: flex
  flex-direction: column
  gap: 8px
  background: #384053
  border-radius: 30px
  position: relative
  overflow: hidden
  animation: slidein 40s linear infinite
  cursor: pointer

  @media (min-width: 1730px)
    width: 432px
    height: 355px

  @media (max-width: 900px)
    width: 353px
    height: 252px
    padding: 15px 10px

  @media (max-width: 500px)
    width: 320px
    height: 223px
    padding: 15px 14px

  @keyframes slidein
    0%, 100%
      transform: translateX(70%)
    50%
      transform: translateX(-70%)

.review__block_header
  font-size: 20px
  font-style: normal
  font-weight: 500
  line-height: normal

  @media (min-width: 1730px)
    font-size: 24px

  @media (max-width: 900px)
    font-size: 18px

  @media (max-width: 500px)
    font-size: 16px

.review__block_position
  color: #B0B8CB
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: normal

  @media (min-width: 1730px)
    font-size: 18px

  @media (max-width: 900px)
    font-size: 14px

  @media (max-width: 500px)
    font-size: 14px

.review__block_text
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: normal

  @media (min-width: 1730px)
    font-size: 20px

  @media (max-width: 900px)
    font-size: 16px

  @media (max-width: 500px)
    font-size: 14px