@import "/src/assets/styles/variables.sass"
@import "/src/assets/styles/mixins.sass"

.fieldValidationBorder
  padding: 0 0 40px 47px
  border-left: 1px dashed $c-wild-blue
  position: relative

  &::before
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%238586A5" fill="%231A2235"/></svg>')
    position: absolute
    top: 0
    left: 0
    transform: translate(-53%, -20%)

  &:first-of-type
    padding-top: 0

  &:last-of-type
    margin: 0 0 40px
    padding-bottom: 0

    &::after
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%238586A5" fill="%231A2235"/></svg>')
      position: absolute
      bottom: 0
      left: 0
      transform: translate(-53%, 50%)

  &.success
    border-left: 1px dashed $c-validation-green

    &::before
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%23399830" fill="%23399830"/></svg>')

    &:last-of-type
      &::after
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%23399830" fill="%23399830"/></svg>')

  &.errors
    border-left: 1px dashed $c-accent

    &::before
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none"><circle cx="6.5" cy="6.5" r="6" stroke="%23B63940" fill="%23B63940"/></svg>')

.formGroup
  display: flex
  flex-direction: column
  width: 27%
  min-width: 310px

  @media screen and (min-width: 1900px)
    width: 40%

  &.formGroupTextarea
    min-width: 656px
    width: 63%

  &.formGroupContacts
    min-width: 716px
    width: calc(54% + 96px)

    @media screen and (min-width: 1900px)
      width: calc(60% + 96px)

  &.textFormGroup
    width: 68%

.label
  font-weight: 700

  @media screen and (min-width: 1900px)
    font-size: 18px

  &.contactsLabel
    margin-bottom: 24px

  &::after
    padding-left: 8px
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M9.91083 8.34656C9.89815 8.36768 9.88144 8.3861 9.86165 8.40075C9.84185 8.41541 9.81936 8.42603 9.79546 8.43199C9.77157 8.43795 9.74673 8.43915 9.72237 8.43551C9.69801 8.43187 9.6746 8.42347 9.65349 8.41078L6.18755 6.33094V10.125C6.18755 10.1747 6.1678 10.2224 6.13263 10.2576C6.09747 10.2927 6.04978 10.3125 6.00005 10.3125C5.95032 10.3125 5.90263 10.2927 5.86747 10.2576C5.8323 10.2224 5.81255 10.1747 5.81255 10.125V6.33094L2.34661 8.41078C2.3255 8.42346 2.30209 8.43186 2.27773 8.4355C2.25337 8.43913 2.22854 8.43793 2.20464 8.43197C2.15638 8.41993 2.11488 8.3892 2.08927 8.34656C2.06366 8.30392 2.05604 8.25285 2.06808 8.20459C2.08012 8.15633 2.11085 8.11483 2.15349 8.08922L5.63536 6L2.15349 3.91078C2.13237 3.8981 2.11396 3.88139 2.09931 3.86159C2.08466 3.84179 2.07404 3.81931 2.06808 3.79541C2.05604 3.74715 2.06366 3.69608 2.08927 3.65344C2.10195 3.63232 2.11867 3.61391 2.13846 3.59926C2.15826 3.58461 2.18074 3.57399 2.20464 3.56803C2.2529 3.55599 2.30397 3.56361 2.34661 3.58922L5.81255 5.66906V1.875C5.81255 1.82527 5.8323 1.77758 5.86747 1.74242C5.90263 1.70725 5.95032 1.6875 6.00005 1.6875C6.04978 1.6875 6.09747 1.70725 6.13263 1.74242C6.1678 1.77758 6.18755 1.82527 6.18755 1.875V5.66906L9.65349 3.58922C9.6746 3.57654 9.69801 3.56814 9.72237 3.5645C9.74673 3.56087 9.77156 3.56207 9.79546 3.56803C9.81936 3.57399 9.84184 3.58461 9.86164 3.59926C9.88144 3.61391 9.89815 3.63232 9.91083 3.65344C9.92351 3.67455 9.93191 3.69796 9.93554 3.72232C9.93918 3.74668 9.93798 3.77151 9.93202 3.79541C9.92606 3.81931 9.91544 3.84179 9.90079 3.86159C9.88614 3.88139 9.86773 3.8981 9.84661 3.91078L6.36474 6L9.84661 8.08922C9.86773 8.1019 9.88615 8.11861 9.9008 8.1384C9.91546 8.1582 9.92608 8.18069 9.93204 8.20459C9.938 8.22848 9.9392 8.25332 9.93556 8.27768C9.93192 8.30204 9.92352 8.32545 9.91083 8.34656Z" fill="%23B63940"/></svg>')

.labelText
  font-weight: 700
  margin-bottom: 24px

  &::after
    padding-left: 8px
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M9.91083 8.34656C9.89815 8.36768 9.88144 8.3861 9.86165 8.40075C9.84185 8.41541 9.81936 8.42603 9.79546 8.43199C9.77157 8.43795 9.74673 8.43915 9.72237 8.43551C9.69801 8.43187 9.6746 8.42347 9.65349 8.41078L6.18755 6.33094V10.125C6.18755 10.1747 6.1678 10.2224 6.13263 10.2576C6.09747 10.2927 6.04978 10.3125 6.00005 10.3125C5.95032 10.3125 5.90263 10.2927 5.86747 10.2576C5.8323 10.2224 5.81255 10.1747 5.81255 10.125V6.33094L2.34661 8.41078C2.3255 8.42346 2.30209 8.43186 2.27773 8.4355C2.25337 8.43913 2.22854 8.43793 2.20464 8.43197C2.15638 8.41993 2.11488 8.3892 2.08927 8.34656C2.06366 8.30392 2.05604 8.25285 2.06808 8.20459C2.08012 8.15633 2.11085 8.11483 2.15349 8.08922L5.63536 6L2.15349 3.91078C2.13237 3.8981 2.11396 3.88139 2.09931 3.86159C2.08466 3.84179 2.07404 3.81931 2.06808 3.79541C2.05604 3.74715 2.06366 3.69608 2.08927 3.65344C2.10195 3.63232 2.11867 3.61391 2.13846 3.59926C2.15826 3.58461 2.18074 3.57399 2.20464 3.56803C2.2529 3.55599 2.30397 3.56361 2.34661 3.58922L5.81255 5.66906V1.875C5.81255 1.82527 5.8323 1.77758 5.86747 1.74242C5.90263 1.70725 5.95032 1.6875 6.00005 1.6875C6.04978 1.6875 6.09747 1.70725 6.13263 1.74242C6.1678 1.77758 6.18755 1.82527 6.18755 1.875V5.66906L9.65349 3.58922C9.6746 3.57654 9.69801 3.56814 9.72237 3.5645C9.74673 3.56087 9.77156 3.56207 9.79546 3.56803C9.81936 3.57399 9.84184 3.58461 9.86164 3.59926C9.88144 3.61391 9.89815 3.63232 9.91083 3.65344C9.92351 3.67455 9.93191 3.69796 9.93554 3.72232C9.93918 3.74668 9.93798 3.77151 9.93202 3.79541C9.92606 3.81931 9.91544 3.84179 9.90079 3.86159C9.88614 3.88139 9.86773 3.8981 9.84661 3.91078L6.36474 6L9.84661 8.08922C9.86773 8.1019 9.88615 8.11861 9.9008 8.1384C9.91546 8.1582 9.92608 8.18069 9.93204 8.20459C9.938 8.22848 9.9392 8.25332 9.93556 8.27768C9.93192 8.30204 9.92352 8.32545 9.91083 8.34656Z" fill="%23B63940"/></svg>')

  @media screen and (min-width: 1900px)
    font-size: 18px

.subText
  color: $c-wild-blue
  font-size: 16px
  font-weight: 400
  line-height: 1.5
  letter-spacing: 0.5px

.textInput
  @include text-input
  margin-bottom: 4px

  &__link
    margin-bottom: 30px

  &__linkDescription
    margin-top: 30px

  &:focus
    @include element-focus

.textInputTextarea
  @include text-input
  height: 150px
  position: relative
  &:hover
    outline: 1px solid $c-additional

  &::placeholder
    color: $c-wild-blue

  &.errors
    border: 1px solid $c-accent

    &:hover
      outline: 1px solid $c-accent

.radioWrapper
  display: flex

.button
  @include green-button

  &:focus
    @include element-focus

  &__testMessage
    margin-bottom: 20px

.inputImage
  position: absolute
  z-index: -1
  opacity: 0

.image
  display: block

  margin-top: 15px

  width: 60px
  height: 60px

  object-fit: contain

.imageButton
  margin-top: 15px
  margin-bottom: 4px
  padding: 1rem 3rem
  width: 300px

  &__icon
    display: block

    margin-right: 10px

    color: $c-transparent-green

    transition: color 0.15s

  &:hover .imageButton__icon
    color: $c-primary-green

.inputImage:focus + .imageButton
  @include element-focus

.checkBoxWrapper
  &:not(:last-child)
    margin-bottom: 30px

.wrapperMailingName
  display: flex
  flex-direction: column
  width: 100%
  position: relative

.uploadedFileInput
  width: 100%

.uploadedFileInput input[type=file]
  display: none

.uploadedFileInputDisabled input[type=file]
  display: none

.uploadedFileInput label
  cursor: pointer
  color: $c-wild-blue
  padding: 8px 15px
  line-height: 34px
  border: 1px solid $c-additional
  border-radius: 4px
  user-select: none
  width: 100%
  position: relative

  &::after
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 26 28" fill="none"><path d="M25 13.1888L13.6533 24.7819C12.2633 26.2021 10.378 27 8.41216 27C6.44633 27 4.56102 26.2021 3.17097 24.7819C1.78092 23.3616 1 21.4354 1 19.4269C1 17.4183 1.78092 15.4921 3.17097 14.0718L14.5176 2.47875C15.4443 1.53192 16.7012 1 18.0117 1C19.3223 1 20.5792 1.53192 21.5059 2.47875C22.4326 3.42557 22.9532 4.70975 22.9532 6.04876C22.9532 7.38778 22.4326 8.67195 21.5059 9.61878L10.1469 21.2119C9.68353 21.6853 9.05509 21.9512 8.39981 21.9512C7.74454 21.9512 7.1161 21.6853 6.65275 21.2119C6.1894 20.7384 5.92909 20.0964 5.92909 19.4269C5.92909 18.7573 6.1894 18.1153 6.65275 17.6418L17.1351 6.94442" stroke="%238586A5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    position: absolute
    right: 3%
    top: 20%

.uploadedFileInputClose
  width: 100%

.uploadedFileInputClose input[type=file]
  display: none

.uploadedFileInputClose label
  cursor: pointer
  color: $c-wild-blue
  padding: 8px 15px
  line-height: 34px
  border: 1px solid $c-additional
  border-radius: 4px
  user-select: none
  width: 100%
  position: relative

.closeIcon
  position: absolute
  right: 18px
  top: 18px
  color: $c-wild-blue
  z-index: 3

  &:hover
    color: white

.uploadedFileInputDisabled label
  border: 1px solid $c-disabled
  color: $c-wild-blue
  padding: 8px 15px
  line-height: 34px
  border-radius: 4px
  user-select: none
  width: 100%
  position: relative

.uploadedFileInput label p
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 275px

.uploadedFileInput label:hover
  outline: 1px solid $c-additional

.contactsTable
  width: 100%
  border-radius: 8px
  background: $c-secondary
  height: 77.5%
  align-self: flex-end
  overflow-x: hidden
  overflow-y: scroll
  max-height: 300px
  scrollbar-width: thin

  &::-webkit-scrollbar
    display: block

  @media screen and (min-width: 1900px)
    width: 50%

.contactTableList
  padding: 0

.contactTableItem
  padding: 4px 58px 4px 11px
  list-style-type: none
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: 1.71
  background: $c-secondary
  color: $c-wild-blue
  cursor: pointer
  position: relative

  &::after
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" ><path d="M17.1875 10C17.1875 10.0829 17.1546 10.1624 17.096 10.221C17.0374 10.2796 16.9579 10.3125 16.875 10.3125H10.3125V16.875C10.3125 16.9579 10.2796 17.0374 10.221 17.096C10.1624 17.1546 10.0829 17.1875 10 17.1875C9.91712 17.1875 9.83763 17.1546 9.77903 17.096C9.72042 17.0374 9.6875 16.9579 9.6875 16.875V10.3125H3.125C3.04212 10.3125 2.96263 10.2796 2.90403 10.221C2.84542 10.1624 2.8125 10.0829 2.8125 10C2.8125 9.91712 2.84542 9.83763 2.90403 9.77903C2.96263 9.72042 3.04212 9.6875 3.125 9.6875H9.6875V3.125C9.6875 3.04212 9.72042 2.96263 9.77903 2.90403C9.83763 2.84542 9.91712 2.8125 10 2.8125C10.0829 2.8125 10.1624 2.84542 10.221 2.90403C10.2796 2.96263 10.3125 3.04212 10.3125 3.125V9.6875H16.875C16.9579 9.6875 17.0374 9.72042 17.096 9.77903C17.1546 9.83763 17.1875 9.91712 17.1875 10Z" fill="%238586A5" /></svg>')
    position: absolute
    right: 34px
    top: 60%
    transform: translateY(-50%)

  &:hover
    background: $c-wild-blue
    color: white

    &::after
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" ><path d="M17.1875 10C17.1875 10.0829 17.1546 10.1624 17.096 10.221C17.0374 10.2796 16.9579 10.3125 16.875 10.3125H10.3125V16.875C10.3125 16.9579 10.2796 17.0374 10.221 17.096C10.1624 17.1546 10.0829 17.1875 10 17.1875C9.91712 17.1875 9.83763 17.1546 9.77903 17.096C9.72042 17.0374 9.6875 16.9579 9.6875 16.875V10.3125H3.125C3.04212 10.3125 2.96263 10.2796 2.90403 10.221C2.84542 10.1624 2.8125 10.0829 2.8125 10C2.8125 9.91712 2.84542 9.83763 2.90403 9.77903C2.96263 9.72042 3.04212 9.6875 3.125 9.6875H9.6875V3.125C9.6875 3.04212 9.72042 2.96263 9.77903 2.90403C9.83763 2.84542 9.91712 2.8125 10 2.8125C10.0829 2.8125 10.1624 2.84542 10.221 2.90403C10.2796 2.96263 10.3125 3.04212 10.3125 3.125V9.6875H16.875C16.9579 9.6875 17.0374 9.72042 17.096 9.77903C17.1546 9.83763 17.1875 9.91712 17.1875 10Z" fill="white" /></svg>')
      position: absolute
      right: 34px
      top: 60%
      transform: translateY(-50%)

.contactsTableItemText
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.stepsInstruction
  display: flex
  width: 385px
  margin-top: 35px
  margin-left: 128px
  color: $c-additional

.stepsInstructionContacts
  display: flex
  width: 385px
  margin-top: 35px
  color: $c-additional

.stepsInstructionText
  display: flex
  align-items: center
  width: 385px
  margin-top: 35px
  color: $c-additional

.stepsInstructionTextarea
  display: flex
  width: 385px
  margin-top: 35px
  color: $c-additional
  align-items: flex-start
  padding-top: 32px

.mailingChannelWrapper
  display: flex

.channelRedStar
  color: red

.mailingChannelWrapperStep
  display: flex
  gap: 16px

  &.mailingChannelWrapperStepContacts
    gap: 96px

.stepsWrapperText
  display: flex
  align-items: baseline
  margin-top: 60px

.mailingNameInput
  width: 100%
  margin: 24px 0 0

.mailingNameInputText
  width: 100%
  min-width: 656px
  display: flex
  flex-direction: column
  align-items: flex-end
  gap: 8px
  position: relative

  @media screen and (max-width: 768px)
    min-width: unset

.errorMessage
  font-size: 10px
  margin: 2px 0 0 16px
  line-height: 1.2
  left: 0
  bottom: -15px
  align-self: start
  color: $c-error
  position: absolute

.checkMailinMethod
  color: $c-additional
  font-size: 16px
  font-weight: 400

  &.checkMailinMethodContacts
    width: 130%

.fileExample
  color: $c-additional
  font-size: 14px
  margin-bottom: 16px
  border-bottom: 1px solid $c-additional
  cursor: pointer

  &:hover
    color: $c-support-text
    border-bottom: 1px solid $c-support-text
