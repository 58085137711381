@import "/src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"


.modal
  position: fixed
  z-index: 9999
  top: 0
  left: 0
  height: 100%
  width: 100%

  pointer-events: none
  transition: all 0.5s ease-in
  opacity: 0

  // overflow-y: auto

.modal__active
  opacity: 1
  pointer-events: all
  backdrop-filter: blur(10px)

.content
  @include container
  width: fit-content

  margin: 10vh auto 0

  transform: scale(0.6)
  transition: 0.2s all

.content__active
  transform: scale(1)

.closeButton
  position: absolute
  top: 20px
  right: 20px
  background-color: transparent
  border: none
  color: white
  font-size: 20px
  width: 40px
  height: 40px
  cursor: pointer
  transition: transform 0.2s
  &:hover
    color: #B63940
  &:active
    transform: scale(0.9)




