@import "src/assets/styles/mixins.sass"
@import "/src/assets/styles/variables.sass"

.innerWrapper
  display: flex
  justify-content: space-between
  margin-bottom: 28px

.title
  padding-left: 12px
  @include header(20px)

.csvLink
  text-decoration: underline
  color: $c-additional
  padding-right: 32px

.csvLink:hover
  opacity: 0.8

.csvLink:active
  opacity: 0.6

.loaderWrapper
  display: flex
  justify-content: center
  align-items: center
  height: 30vh