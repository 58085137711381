@import "/src/assets/styles/mixins.sass"

.registrationForm
  @include form

.title
  @include title

.error
  @include error
  color: #FFB4AB
  
.registration__wrapper
  margin-top: 110px
  width: 100%
  padding: 40px 0px 50px 0px
  background: linear-gradient(101deg, #019BF3 0%, #022B73 100%)

  @media (min-width: 1730px)
    margin-top: 120px

  @media (max-width: 500px)
    margin-top: 56px

.registration__header
  text-align: center
  font-size: 38px
  font-style: normal
  font-weight: 700
  line-height: normal

  @media (max-width: 500px)
    font-size: 24px
    font-weight: 500

.registration__content
  display: flex
  justify-content: center
  padding-top: 36px

.registrationForm
  width: 490px
  padding: 20px

.registration__button
  padding: 16px 60px
  justify-content: center
  align-items: center
  border-radius: 30px
  background: #FFF
  color: #2B3243
  text-align: center
  font-family: Roboto
  font-size: 20px
  font-style: normal
  font-weight: 500
  line-height: normal

  &:hover
    background: #1A2235
    color: white

  &:active
    background: #384053

.successMessage
  display: flex
  flex-direction: column
  align-items: center
  row-gap: 15px
  width: 500px
  font-family: 'Roboto', sans-serif
  font-size: 20px
  line-height: 24px
  padding: 30px 43px 43px

.btnSucess
  width: 450px

.loaderContainer
  display: flex
  justify-content: center
