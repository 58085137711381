.header__wrapper
  display: flex

  @media (max-width: 500px)
    padding: 16px 0px 16px 16px

.header__menu
  display: flex
  justify-content: flex-end
  width: 100%
  font-size: 20px
  font-weight: medium
  align-items: center

  @media (max-width: 500px)
    font-size: 16px
    font-weight: 500
    justify-content: flex-start
    gap: 8px
    flex-direction: row-reverse

.header__menu_btn
  border-radius: 30px
  background: #B63940
  padding: 16px 0
  width: 180px

  &:hover
    background: #D4575E

  &:active
    background: transparent
    border: 2px solid #B63940
    background: #2B3243

  @media (max-width: 500px) 
    width: 118px
    text-align: center

.scrolled
  padding: 0 40px
  position: fixed
  top: 20px
  left: 0
  right: 0
  z-index: 1000
  align-items: flex-start

  @media (max-width: 500px) 
    padding: 0

.header__menu_wrapper
  display: flex
  gap: 34px
  background: #2B3243
  padding: 16px 84px 16px 34px
  border-radius: 30px
  margin-right: -40px
  animation: slideIn 0.8s ease

  @media (max-width: 900px)
    gap: 16px
    padding: 16px 60px 16px 34px

  @media (max-width: 900px)
    animation: none

  @media (max-width: 500px)
    display: flex
    flex-direction: column
    border-radius: 4px
    padding: 0
    gap: 2px
    background: #384053
    position: absolute
    top: 16px
    right: 100px
    z-index: 999

  @keyframes slideIn
    from
      transform: translateX(30%)
      z-index: -999
    to
      transform: translateX(0%)
      z-index: -999

.header__menu_item
  border-bottom: 2px solid transparent
  transition: border-bottom 0.3s ease

  &:hover
    border-bottom: 2px solid #F8F5F5

  @media (max-width: 500px)
    border-bottom: none
    padding: 18px 20px
    border-bottom: 1px solid #8586A5

    &:hover
      background: #0556AB
      border-bottom: none

.header__menu_icon
  cursor: pointer
  padding-right: 34px

  @media (max-width: 500px)
    padding-right: 6px

.header__logo
  display: flex
  align-items: center