@import "/src/assets/styles/mixins.sass"
@import "/src/assets/styles/variables.sass"

.recoveryAlertWrapper
  display: flex
  flex-direction: column
  align-items: center
  padding-top: 68px

  
.recoveryForm
  @include form
  width: 500px
  display: flex
  flex-direction: column
  align-items: center
  gap: 24px
  padding: 20px 44px 32px
  background: #2B3243
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5)
  border-radius: 8px

.title
  @include title

.returnLink:hover
  opacity: 0.8

.returnLink:active
  opacity: 0.6

.loaderContainer
  padding-left: 30px

.emailResetInput
  color: black
  padding: 12px
  width: 100%
  border-radius: 4px