@import "/src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.wrapper
  display: flex
  flex-direction: column
  // height: 57%
  gap: 16px

  @media (max-width: 500px)
    flex-direction: column
    justify-content: center
    gap: 16px

.block__top
  display: flex
  justify-content: space-between

  @media (max-width: 500px)
    flex-direction: column
    justify-content: center
    gap: 16px

.block__bottom
  display: flex
  justify-content: space-between

  @media (max-width: 500px)
    flex-direction: column
    justify-content: center
    gap: 16px

.parserChart
  width: calc(50% - 10px)
  padding: 16px 24px 24px
  background-color: #2B3243
  border-radius: 8px
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.25)
  height: 250px

  @media (min-width: 1800px)
    height: 300px

  @media (max-width: 500px)
    width: 100%
    height: 248px
    border-radius: 0
    box-shadow: none
