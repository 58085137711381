@import "/src/assets/styles/mixins.sass"
@import "/src/assets/styles/variables.sass"
  
.recoveryForm
  @include form

.title
  @include title

.returnLink:hover
  opacity: 0.8

.returnLink:active
  opacity: 0.6

.loaderContainer
  padding-left: 30px

.emailResetInput
  color: white
  padding: 12px
  width: 100%
  border-radius: 4px
  background: #2B3243
  outline: 1px solid #8586A5