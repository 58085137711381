@import "src/assets/styles/mixins.sass"
@import "/src/assets/styles/variables.sass"

.wrapper
  background-color: $c-secondary

.periodWrapper
  display: grid
  grid-template-columns: 260px 260px
  grid-template-areas: "title title" "input1 input2"
  row-gap: 18px
  column-gap: 24px

  padding-top: 20px
  padding-left: 20px

.paymentFail
  color: $c-error

.periodTitle
  grid-area: title

  font-weight: 600

.periodInput:nth-child(1)
  grid-area: input2

.periodInput:nth-child(2)
  grid-area: input1

.select
  background-color: transparent

  width: 150px

  font-size: 14px
  font-weight: 500

  border: none
  border-bottom: 1px solid $c-additional

select option
  background-color: $c-main

.headCell
  width: 220px

.paymentRow
  max-height: 24px
  
.paymentCell
  height: 24px
  overflow: hidden

.loaderWrapper
  display: flex
  justify-content: center
  align-items: center
  height: 30vh
  width: 100%
  border: none

  
  
.totalAmount
  display: flex
  column-gap: 22%
  font-size: 16px
  font-weight: 600
  margin: 0 20px
  padding: 10px 0 25px
  border-top: 2px solid $c-additional
      