@import "/src/assets/styles/variables.sass"

.userHeadCell
  min-width: 150px
  vertical-align: top

.userHeadCell__block
  z-index: 1
  vertical-align: top

.toggle
  z-index: 0

.usersRow
  transition: all 0.15s

.usersRow:hover
  color: $c-additional
  cursor: pointer

.bannedUser
  color: $c-error