@import "/src/assets/styles/variables.sass"

.wrapper
  padding: 0 40px
  overflow-x: hidden

  @media (max-width: 500px)
    padding: 0
    display: flex
    justify-content: center

.wrapper_mobile
  padding: 0 20px
  overflow-x: hidden

  @media (max-width: 500px)
    padding: 0

.main
  margin-top: 24px

.promo
  margin-bottom: 44px
  padding-top: 40px

.experience
  margin-bottom: 100px


@media (min-width: 1900px)
  .main
    margin-top: 60px

  .promo
    margin-bottom: 135px
