@import "src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"
  
.tariffTitle
  @include header(14px)
  display: flex
  align-items: center
  height: 100%
  
.tariffDescription
  display: flex
  width: 100%
  font-size: 14px
  gap: 48px
  align-items: center

.adminCustomCell
  height: 80px

.tariffMethods
  li
    position: relative

    padding-left: 20px

    &::before
      content: ""
      position: absolute
      top: 50%
      left: 8px
      width: 3px
      height: 3px
      border-radius: 50%
      background-color: #ffffff

.updateButton
  padding: 4px 0
  background-color: #8586A5

.tariffForm
  display: flex
  flex-direction: column
  row-gap: 30px

  width: 500px
  padding: 30px 44px 44px

.tariffForm_title
  @include header(20px)
  text-align: center

.tariffForm_subTitle
  @include header(16px)
  margin-bottom: 24px

.tariffForm_parsers
  display: flex
  column-gap: 20px

.tariffForm_methodsTitle
  font-size: 16px
  margin-bottom: 22px

.tariffForm_checkbox:not(:last-child)
  margin-bottom: 20px

.tariffForm_error
  @include error

.headCell
  z-index: 1

.toggle
  z-index: 0

.inactive
  color: $c-error

.managment
  display: flex
  justify-content: flex-end
  gap: 24px

.btn_change
  width: 100px

.loaderContainer
  display: flex
  justify-content: center
