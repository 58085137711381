.wrapper
  background-color: #1A2235

  @media (max-width: 500px)
    height: 350px

.footer__content
  display: flex
  flex-direction: column
  gap: 12px
  padding: 50px 0 120px 80px

  @media (max-width: 900px)
    padding: 40px 20px
    gap: 10px

  @media (max-width: 500px)
    padding: 20px
    gap: 16px

.footer__social
  display: flex
  gap: 20px
  align-items: center

  @media (max-width: 500px)
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 8px

.footer__social_element
  display: flex
  gap: 8px
  align-items: center

  &:hover
    border-bottom: 1px solid #FFF

// .footer__text
//   display: flex
//   // align-items: center
//   gap: 8px

.footer__text_link
  border-bottom: 1px solid #FFF