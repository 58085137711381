@import "/src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"
  
.toolItem
  display: grid
  grid-template-areas: "name name" "characteristics options"
  align-items: center
  row-gap: 5px
  padding-bottom: 4px
  font-size: 16px
  border-bottom: 1px solid $c-support-text
  margin-bottom: 12px

.toolItem div:nth-child(1)
  grid-column: 1 / -1

.toolItem div:nth-child(2)
  grid-column: 1 / 2

.toolName
  grid-area: name
  display: flex
  align-items: center
  column-gap: 12px
  padding-left: 4px
  padding-bottom: 4px
  font-size: 14px
  font-weight: lighter

.toolCharacteristics
  grid-area: characteristics
  display: flex
  column-gap: 20px
  padding-left: 30px

  span
    font-size: 12px
    font-weight: lighter


.toolsOptions
  grid-area: options
  align-self: flex-end
  display: flex
  align-items: center
  justify-content: flex-end
  column-gap: 15px

.toolDate,
.toolMailings,
.toolDuration
  display: flex
  align-items: center
  column-gap: 5px
  color: #bbb6b6
  > svg *
    fill: #8586A5

.iconFailed
  color: $c-error

.iconWaiting
  color: blue
