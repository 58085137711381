.FailPageWrapper
  padding: 16px 78px

.FailPageInfo
  display: flex
  justify-content: center
  align-items: center
  gap: 64px

.FailPageTitle
  font-size: 52px
  color: #B63940

.FailPageParagraph
  padding-top: 32px
  font-size: 40px
  font-weight: 500

.FailPageMessage
  padding-top: 32px
  color: #8586A5
  font-size: 24px
  line-height: 28px

.FailPageBtn
  max-width: 450px
  margin-top: 32px
  background: #B63940
  box-shadow: 0px 4px 20px 0px #FF000E99
  padding: 16px 143px
  text-align: center
  transition: all 0.35s

.FailPageBtn:hover
  background-color: #7b070d
  filter: none

.FailPagePng
  width: 50%