@import "/src/assets/styles/variables.sass"
@import "src/assets/styles/mixins.sass"

.wrapper
  display: flex
  flex-direction: column
  gap: 24px
  width: 100%
  max-width: 456px
  height: 954px
  background-color: #2B3243
  padding: 8px 0px 16px 0px
  border-radius: 8px

  @media (max-width: 1700px)
    max-width: 335px

  @media (max-width: 1700px)
    max-width: 100%
    border-radius: 0

.tariff
  display: flex
  flex-direction: column
  width: 100%
  padding: 6px 16px 8px 16px
  justify-content: center
  align-items: flex-start
  gap: 12px
  border-bottom: 1px solid #424A5D

.isTariff
  display: flex
  flex-direction: column
  gap: 12px

.tariff__header
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between

.tariffName
  font-size: 18px
  font-weight: 500

.tariffCost
  font-size: 18px
  font-weight: 500

.tariffEndDate
  color: #8586A5
  font-size: 14px
  font-weight: 400

.isNoTariff
  display: flex
  flex-direction: column
  gap: 12px

.isNoTariff__header
  color: #399830
  font-size: 18px
  font-weight: 500
  cursor: pointer

.isNoTariff__text
  color: #8586A5
  font-size: 14px
  font-weight: 400

.historyOperation__header
  font-size: 16px
  font-weight: 500
  padding: 8px 16px

.operation
  display: flex
  flex-direction: column
  justify-content: center
  align-content: space-around
  gap: 12px
  align-self: stretch
  padding: 16px 16px 8px 16px
  border-bottom: 1px solid #424A5D

  &.noData
    justify-content: center

.operationTitle
  padding-bottom: 16px
  font-size: 16px
  font-weight: 500
  line-height: normal
  text-align: center

.operationUl
  height: 158px
  overflow-y: auto

.operationLi
  display: flex
  justify-content: space-between
  padding-bottom: 12px
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal

.green_text
  color: green

.red_text
  color: red

.otherText
  color: var(--Gray, #8586A5)
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal

.tariffInformation
  padding: 12px 16px 16px 16px
  display: flex
  flex-direction: column
  gap: 24px

.tariffLimits
  display: flex
  flex-direction: column
  gap: 28px

.tariffLimits__simultParsing
  display: flex
  flex-direction: column
  gap: 16px

.tariffLimits__header
  font-size: 16px
  font-weight: 500

.tariffLimits__progress
  display: flex
  justify-content: space-between

.tariffLimits__progressText
  color: #8586A5
  font-size: 16px
  font-weight: 500

.tariffLimits__progressbar
  width: 244px

.tariff__optionParsing
  display: flex
  flex-direction: column
  gap: 12px
  padding-top: 20px

.tariff__optionParsing_text
  font-size: 16px
  font-weight: 500

.optionBlock
  display: flex
  flex-direction: column
  gap: 12px
  font-size: 14px
  color: #1A2235
  font-weight: 500

.optionBlock__geo
  width: 94px
  padding: 6px 8px
  border-radius: 12px
  background: #8586A5

.optionBlock__members
  width: 194px
  padding: 6px 8px
  border-radius: 12px
  background: #8586A5

.optionBlock__activity
  width: 281px
  padding: 6px 8px
  border-radius: 12px
  background: #8586A5

