@import "/src/assets/styles/variables.sass"
@import "/src/assets/styles/mixins.sass"

.tarifs
  // width: 410px
  // height: 720px
  background: #2B3243
  border-radius: 8px
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25), 0px -4px 10px rgba(0, 0, 0, 0.25)
  padding: 40px
  display: flex
  flex-direction: column
  gap: 24px

.greenBorder
  border: 2px solid green

.block__wrapper
  display: flex
  flex-direction: column
  gap: 6px

.block__hardSize
  min-height: 116px

.header
  font-size: 24px
  font-weight: 700
  color: #B63940

.subTitle
  font-size: 16px
  font-style: normal
  font-weight: 600
  line-height: normal

.list
  list-style-position: inside

.list li
  padding-top: 12px
  padding-left: 4px
  list-style-type: disc

.buttonWrapper
  display: flex
  justify-content: center
  min-height: 48px

  