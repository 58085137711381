.startInfo__wrapper
  width: 100%
  height: 600px
  border-radius: 30px
  background: linear-gradient(101deg, #019BF3 0%, #022B73 100%)
  display: flex

  @media (min-width: 1730px)
    height: 716px
  
  @media (max-width: 900px)
    height: 324px

  @media (max-width: 500px)
    height: 281px
    border-radius: 0
    display: flex
    justify-content: center
    background: linear-gradient(103deg, #0577D1 0%, #05489C 119.93%)

.startInfo__innerWrapper
  padding-left: 113px
  padding-top: 102px
  display: flex
  flex-direction: column

  @media (max-width: 900px)
    padding-left: 25px
    padding-top: 38px

  @media (max-width: 500px)
    padding-left: 0px
    padding-top: 17px
    align-items: center

.startInfo__header
  position: relative
  font-size: 38px
  font-style: normal
  font-weight: 700
  line-height: normal

  @media (min-width: 1730px)
    font-size: 46px

  @media (max-width: 900px)
    font-size: 32px

  @media (max-width: 500px)
    font-size: 28px
    text-align: center

.startInfo_ticker
  margin-top: 37px
  display: flex
  flex-direction: column
  justify-content: center
  gap: 13px
  font-family: Roboto
  font-style: normal
  font-weight: 400
  line-height: normal
  width: 450px
  height: 60px
  overflow: hidden

  @media (min-width: 1730px)
    font-size: 24px
    width: 600px

  @media (max-width: 900px)
    margin-top: 16px
    width: 350px

  @media (max-width: 500px)
    margin-top: 16px
    width: 300px

.ticker
  display: flex
  gap: 13px
  animation: tickerAnimation 5s ease-in-out infinite

  @keyframes tickerAnimation
    0%
      transform: translateY(100%)
    3%
      transform: translateY(0%)
    20%
      transform: translateY(0%)
    40%
      transform: translateY(0%)
    60%
      transform: translateY(0%)
    80%
      transform: translateY(0%)
    97%
      transform: translateY(0%)
    100%
      transform: translateY(-100%)

.startinfo__btn
  margin-top: 50px
  border-radius: 30px
  background: #FFF
  padding: 16px 60px
  color: #1A2235
  font-size: 20px
  font-style: normal
  font-weight: 600
  line-height: normal

  &:hover
    background: #1A2235
    color: white

  &:active
    background: #384053

  @media (max-width: 900px)
    margin-top: 16px
    padding: 16px 30px

  @media (max-width: 500px)
    margin-top: 24px
    padding: 12px 36px
    font-size: 16px
    font-weight: 500
    width: 320px

.startInfo__iPhone_img
  position: relative
  margin-left: auto
  margin-top: auto

  @media (max-width: 500px)
    display: none