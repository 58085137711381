@import "src/assets/styles/variables.sass"

.infoIcon
  position: absolute
  top: 0
  right: 0
  cursor: pointer
  color: $c-additional

  &:hover
    color: white
