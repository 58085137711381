@import "src/assets/styles/variables.sass"

.fileExampleWrapper
  position: relative

.tooltip
  position: relative

.tooltipTopWrapper
  position: absolute
  top: -11%
  left: 20%

.tooltipWrapper
  position: absolute
  top: 0
  right: 0

  &:nth-child(3n)
    top: 50%

  &:nth-child(2n)
    top: 25%

  &:nth-child(3n+1)
    top: 75%

  &:first-child
    top: 0

.tooltipArrow
  width: 17px
  height: 23px
  position: absolute
  transform: translateX(0) translateY(-50%)
  top: 25px
  stroke: #8586A5

.rightTooltipArrow
  transform: translateX(0) translateY(-50%)
  left: $position-tooltip-arrow

.bottomTooltipArrow
  bottom: -51px
  top: unset
  rotate: -90deg
  left: 40px

.tooltipTip
  position: absolute
  border-radius: 4px
  padding: 12px
  color: white
  background: $c-wild-blue
  font-size: 12px
  font-weight: 400
  line-height: normal
  z-index: 3

.rightTooltipTip
  transform: translateX(16px)

.leftTooltipTip
  transform: translateX(calc(-100% - $position-left-tooltip))

.smallTooltipTip
  width: max-content

.bigTooltipTip
  width: 244px
