.aboutUs__wrapper
  width: 1126px
  display: flex
  justify-content: center
  flex-wrap: wrap
  gap: 20px
  padding-top: 100px
  margin: 0 auto

  @media (min-width: 1730px)
    width: 1524px
    gap: 40px
    padding-top: 110px

  @media (max-width: 900px)
    width: 728px

  @media (max-width: 500px)
    width: 320px
    flex-direction: column
    gap: 12px
    padding-top: 32px

.aboutUs__block
  width: calc(50% - 10px)
  height: 297px
  box-sizing: border-box
  background: #384053
  border-radius: 30px
  padding: 40px 40px
  display: flex
  flex-direction: column
  gap: 7px

  @media (min-width: 1730px)
    width: calc(50% - 20px)
    height: 394px

  @media (max-width: 900px)
    height: 262px
    padding: 20px 16px
    gap: 6px

  @media (max-width: 500px)
    width: 320px
    height: 205px
    padding: 20px 16px
    gap: 6px
    justify-content: center

.aboutUs__block.special
  background: #0176C9
  color: #1A2235
  padding: 20px 40px

  @media (min-width: 1730px)
    padding: 30px 40px

  @media (max-width: 900px)
    padding: 20px 16px

  @media (max-width: 500px)
    padding: 20px 16px

.aboutUs__imgWrapper
  overflow: hidden

.aboutUs__img
  padding: 8px
  transition: transform 0.5s ease-in-out

  &:hover
    transform: scale(1.1)
    padding-left: 24px

  @media (min-width: 1730px)
    padding-top: 21px

  @media (max-width: 500px)
    padding-top: 0px

.aboutUs__text_content
  display: flex
  flex-direction: column
  gap: 14px

  @media (max-width: 900px)
    gap: 6px

  @media (max-width: 500px)
    gap: 6px

.aboutUs__text_content.special
  width: 473px

  @media (max-width: 900px)
    width: 100%

  @media (max-width: 500px)
    width: 100%

.aboutUS__header
  font-size: 34px
  font-style: normal
  font-weight: 700
  line-height: normal

  @media (min-width: 1730px)
    font-size: 40px

  @media (max-width: 900px)
    font-size: 24px
    font-weight: 500

  @media (max-width: 500px)
    font-size: 20px
    font-weight: 500

.aboutUS__text
  font-family: Roboto
  font-size: 20px
  font-style: normal
  font-weight: 400
  line-height: normal

  @media (min-width: 1730px)
    font-size: 24px

  @media (max-width: 900px)
    font-size: 18px
    font-weight: 400

  @media (max-width: 500px)
    font-size: 16px
    font-weight: 400

.aboutUs__wrap_text
  display: flex
  flex-direction: column

.aboutUs__text_link
  border-bottom: 1px solid #FFF
  padding-bottom: 4px

.aboutUs__btn
  display: flex
  width: 180px
  padding: 16px 60px
  justify-content: center
  align-items: center
  gap: 10px
  border-radius: 30px
  background: #FFF
  color: #1A2235
  margin-top: 6px

  &:hover
    background: #1A2235
    color: white

  &:active
    background: #384053

  @media (min-width: 1730px)
    margin-top: 10px

  @media (max-width: 900px)
    padding: 16px 48px

  @media (max-width: 500px)
    width: 288px
    padding: 12px 36px

.aboutUs__ul
  list-style-type: disc
  display: flex
  flex-direction: column
  gap: 14px
  padding-left: 16px
  padding-top: 10px
  font-size: 20px
  font-style: normal
  font-weight: 400
  line-height: normal

  @media (min-width: 1730px)
    font-size: 24px

  @media (max-width: 900px)
    gap: 6px
    padding-top: 12px
    font-size: 16px

  @media (max-width: 500px)
    gap: 6px
    padding-top: 12px
    font-size: 14px

