@import "/src/assets/styles/mixins.sass"

.wrapper
  min-width: 500px
  padding: 30px 44px 47px
  display: flex
  flex-direction: column
  gap: 24px
  font-weight: 600

  @media (max-width: 500px)
    min-width: 300px

.header
  @include header(20px)
  align-self: center

.balance
  font-size: 28px
  display: flex
  gap: 16px
  justify-content: center
  align-items: center
  
.balanceHint
  font-size: 16px

.form
  display: flex
  flex-direction: column
  gap: 24px